/* eslint-disable no-unused-vars */
import * as Button from './../../components/Atoms/Button';
import * as Diff from 'deep-object-diff';
import * as Grouped from './../../components/Atoms/Grouped';
import * as SearchDialog from './../../components/Dialog/SearchDialog';
import filesize from 'filesize';

import {
  ACCIDENT_STATUS,
  MAIN_FRAMES,
  PARTS_ALL,
  PARTS_BOTTOM,
  PARTS_LEFT,
  PARTS_RIGHT,
  PARTS_TOP,
  PC_BASE_BLUEPRINT,
  PC_STATUS_BLUEPRINT,
  REPAIR_STATUS,
  GASOLINE_PARTS,
  DIESEL_PARTS,
  LPG_PARTS,
  ELECTRONIC_PARTS,
  HYDROGEN_PARTS,
  HYBRID_PARTS,
  ETC_PARTS,
  WATCHED_PROPS,
} from '../../constants/PerformanceCheckConstants';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  PAYMENT_METHOD,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL3,
  YN,
} from '../../constants/Constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Badge } from 'primereact/badge';
import { BlockUI } from 'primereact/blockui';
import { Checkbox } from 'primereact/checkbox';
import { CommonUtil } from '../../utils/commonUtil';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { EW_TARGET_SHOPS } from './../../constants/EWDataConstants';
import FileState from '../../enums/FileState';
import { Image } from 'primereact/image';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import MolitState from '../../enums/MolitState';
import { Panel } from 'primereact/panel';
import PartRecordMenu from '../../components/Common/PerformanceCheck/PartRecordMenu';
import PopupInputText from '../../components/Common/PopupInputText';
import { RadioButton } from 'primereact/radiobutton';
import { ServiceProvider } from '../../services';
import { SpeedDial } from 'primereact/speeddial';
import SubmitType from '../../enums/SubmitType';
import { Tooltip } from 'primereact/tooltip';
import TransferState from '../../enums/TransferState';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import { compressImages } from '../../utils/compressUtil';
import dayjs from 'dayjs';
import { myInfoSelector } from '../../recoil/selectors';
import performanceCheckUtil from '../../utils/performanceCheckUtil';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import usePrevious from '../../hooks/usePrevious';
import { useRecoilValueLoadable } from 'recoil';
import { RepairApi } from '../../services/PerformanceCheckService';
import { MADE_AT_D } from '../../constants/Constants';
import { MyInfoUtil } from '../../utils/myInfoUtil';

import { performanceReset } from '../../services/PerformanceCheckService';
const baseTitles = _.mapValues(PC_BASE_BLUEPRINT, 'title');
const baseDefaultValues = _.mapValues(PC_BASE_BLUEPRINT, 'defaultValue');
const baseValueItems = _.mapValues(PC_BASE_BLUEPRINT, 'valueItems');
const statusTitles = _.mapValues(PC_STATUS_BLUEPRINT, 'title');
const statusDefaultValues = _.mapValues(PC_STATUS_BLUEPRINT, 'defaultValue');
const statusValueItems = _.mapValues(PC_STATUS_BLUEPRINT, 'valueItems');
const defaultValues = _.merge(
  {
    licensePlateNumber: '',
    association: {},
    enterprise: {},
    shop: {},
    inspector: {},
    customerCompany: {},
    customer: {},
    note: '',
    vehicleInformation: {
      vinRemain: '',
      cnmDetail: '',
      madeAt: 'D',
      VHCTY_ASORT_CODE: '1',
      USE_FUEL_CODE: 'a',
      TRVL_DSTNC: 0,
      detailedModelType: 'SA',
      displacement: 0,
      maxPerson: 0,
      LOSS_STATUS: YN.NO,
    },
    vehicleMaintenance: [],
    paymentInformation: {
      ASSRNC_TY_SE_CODE: '2',
      ICNY_CODE: '',
      VHCTY_ASORT_CODE: '1',
      checkPrice: 0,
      checkPriceVAT: 0,
      paidCheckPrice: 0,
      checkPricePaidYN: YN.NO,
      checkPricePaidAt: null,
      checkPricePaidBy: 'TRAN',
      checkPriceItemId: '',
      discountReason: '',
      discountedCheckPrice: 0,
      insurancePrice: 0,
      insurancePriceVAT: 0,
      insurancePricePaidYN: YN.NO,
      insurancePricePaidAt: null,
      insurancePricePaidBy: 'TRAN',
      insurancePolicyNumber: '',
      insurancePriceCode: '',
      ewYN: YN.NO,
    },
  },
  _.merge(baseDefaultValues, statusDefaultValues)
);

const molitService = ServiceProvider.molit;
const priceService = ServiceProvider.price;
const performanceCheckService = ServiceProvider.performanceCheck;

const PCRegistrationForm = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const myInfo = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );
  const { myAssociationId, myEnterpriseId, myShopId, myRoleCode, shopCode } =
    myInfo;
  const [codes, setCodes] = useState({});
  const [isRecheck, setIsRecheck] = useState(false);
  const [transferError, setTransferError] = useState({
    code: '',
    details: '',
  });
  const [carInformationLoading, setCarInformationLoading] = useState(false);
  const [formRequired, setFormRequired] = useState(true);
  const [workDetails, setWorkDetails] = useState([]);
  const [currentPrices, setCurrentPrices] = useState({
    checkPrice: 0,
    checkPriceVAT: 0,
    totalCheckPrice: 0,
    insurancePrice: 0,
    insurancePriceVAT: 0,
    totalInsurancePrice: 0,
  });
  const [curMolitState, setCurMolitState] = useState('');
  const [curTransferState, setCurTransferState] = useState('');

  const history = useHistory();
  const params = useParams();
  const { statementNumber, dataId } = params;
  const [manualInput, setManualInput] = useState(false);
  const [searchFailed, setSearchFailed] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isEditFormValues, setIsEditFormValues] = useState({
    TRVL_DSTNC: '',
    displacement: 0,
    maxPerson: 0,
    detailedModelType: '',
    VHCTY_ASORT_CODE: '',
  });

  const leftMenuRefs = useRef([]);
  const leftGuideRefs = useRef([]);
  const rightMenuRefs = useRef([]);
  const rightGuideRefs = useRef([]);
  const topMenuRefs = useRef([]);
  const topGuideRefs = useRef([]);
  const bottomMenuRefs = useRef([]);
  const bottomGuideRefs = useRef([]);

  const [isDialogOpened, setIsDialogOpened] = useState({
    userSearch: false,
    customerSearch: false,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues, reValidateMode: 'onSubmit' });

  const currentValues = useWatch({ control });
  const previousValues = usePrevious(currentValues);

  const originPaymentRef = useRef(null);
  const originVehicleRef = useRef(null);

  const location = useLocation();
  const isNewForm = location.pathname.endsWith('/form');
  const isEditForm = !!statementNumber && !!dataId;

  useEffect(() => {
    // 점검지 새로 작성시 & 로그인한 계정의 점검장이 '엔케이모터스'일 때 '특이사항 및 점검자의견' 공란처리
    if (
      isNewForm &&
      (myInfoLoadable?.contents?.shopInfo?.shopName?.includes('엔케이모터스') ||
        myInfoLoadable?.contents?.shopInfo?.shopName?.includes(
          '수원오토공업사'
        ))
    ) {
      setValue('ETC_MATTER', '');
    }
    // 점검지 새로 작성시 & 점검장을 '엔케이모터스'로 선택시 '특이사항 및 점검자의견' 공란처리
    if (
      isNewForm &&
      (currentValues.shop?.shopName === '엔케이모터스' ||
        currentValues.shop?.shopName === '수원오토공업사')
    ) {
      setValue('ETC_MATTER', '');
    }
  }, [
    myInfoLoadable.contents?.shopInfo?.shopName,
    currentValues.shop?.shopName,
    setValue,
  ]);

  const {
    paymentInformation: { checkPrice, checkPriceVAT },
  } = useMemo(() => ({ ...currentValues }), [currentValues]);

  const [loading, setLoading] = useState(false);
  const [isSafeToReset, setIsSafeToReset] = useState(false);
  const [accidentHistory, setAccidentHistory] = useState({
    carHistory: [],
    niceDNR: [],
  });
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    reset: false,
    delete: false,
    accident: false,
    carHistory: false,
    niceDNR: false,
  });

  const [detailedModelTypeOptions, setDetailedModelTypeOptions] = useState(
    _.filter(VEHICLE_TYPE_LEVEL3, { lvl1: 'D', lvl2: '1' })
  );

  const [fileItems, setFileItems] = useState([]);
  const errorCounts = Object.keys(errors).length;

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    // maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles) => {
      setLoading(true);
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      const compressedImages = await compressImages(acceptedFiles, options);

      compressedImages.forEach((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const partCodeAttachedFiles = _.reduce(
        compressedImages,
        (res, v) => {
          res.push({ partCode: 'ETC', file: v, state: FileState.Inserted });
          return res;
        },
        []
      );

      setFileItems((ps) => [...ps, ...partCodeAttachedFiles]);
      setLoading(false);
    },
  });

  const [confirm, setComfirm] = useState(false);

  useEffect(() => {
    if (errorCounts === 0 && confirm) {
      setShowConfirmPopup((ps) => ({ ...ps, submit: true }));
    }
  }, [errorCounts, confirm]);

  const [isSaveRock, setIsSaveRock] = useState(false);
  useEffect(() => {
    if (myInfoLoadable.contents?.shopInfo?.shopName) {
      if (myInfoLoadable.contents?.shopInfo?.shopName?.includes('송이')) {
        setIsSaveRock(true);
      } else {
        setIsSaveRock(false);
      }
    }
  }, [myInfoLoadable.contents?.shopInfo?.shopName]);

  const menus = [
    {
      key: 'exit',
      icon: 'pi pi-times',
      label: '나가기',
      command: () => window.close(),
    },
    {
      key: 'save',
      icon: 'pi pi-save',
      label: '작성완료',
      disabled: loading || !!isSaveRock,
      command: () => {
        handleSubmit((formData) => formData)();

        if (errorCounts === 0 && confirm) {
          return setShowConfirmPopup((ps) => ({ ...ps, submit: true }));
        }

        if (currentValues.TRVL_DSTNC === 0) {
          return window.cerp.toast.error('주행거리 필수입력');
        }

        if (currentValues.ENGINE_STBLT_AT === '') {
          return window.cerp.toast.error('자기진단사항 원동기 필수입력');
        }

        if (currentValues.GRBX_STBLT_AT === '') {
          return window.cerp.toast.error('자기진단사항 변속기 필수입력');
        }

        const front = _.filter(
          fileItems,
          (item) => item.partCode === 'OUT_FRONT'
        );
        const back = _.filter(
          fileItems,
          (item) => item.partCode === 'OUT_REAR'
        );
        if (!front.length || !back.length) {
          window.cerp.toast.warn(
            '사진을 첨부하세요.',
            '정면, 후면 사진은 필수 항목입니다.'
          );
          return false;
        }

        const deleteFront = fileItems?.filter(
          (item) => item.partCode === 'OUT_FRONT' && item.state === 'D'
        );
        const deleteBack = fileItems?.filter(
          (item) => item.partCode === 'OUT_REAR' && item.state === 'D'
        );
        const addFront = fileItems?.filter(
          (item) => item.partCode === 'OUT_FRONT' && item.state === 'I'
        );
        const addBack = fileItems?.filter(
          (item) => item.partCode === 'OUT_REAR' && item.state === 'I'
        );

        if (
          !!deleteFront.length &&
          !addFront.length &&
          !!deleteBack.length &&
          !addBack.length
        ) {
          window.cerp.toast.warn(
            '사진을 첨부하세요.',
            '정면, 후면 사진은 필수 항목입니다.'
          );
          return false;
        }

        if (!currentValues.ETC_MATTER) {
          return window.cerp.toast.warn(
            '특이사항 및 점검자의견은 필수사항입니다.'
          );
        }

        return setComfirm(true);
      },
      isPrimary: true,
    },
    {
      key: 'temp',
      icon: 'pi pi-inbox',
      label: '임시보관',
      disabled: loading,
      command: () => {
        if (currentValues.TRVL_DSTNC === 0) {
          return window.cerp.toast.error('주행거리 필수입력');
        }
        setFormRequired(false);
        handleSubmit((formData) => onSubmit(SubmitType.Temp, formData))();
      },
    },
    {
      key: 'reset',
      icon: 'pi pi-undo',
      label: '초기화',
      command: () => setShowConfirmPopup((ps) => ({ ...ps, reset: true })),
    },
  ];

  const getCarHistoryDatas = async () => {
    setHistoryLoading(true);
    try {
      const data = await RepairApi.getInsurance(
        currentValues.licensePlateNumber
      );
      console.log('historyData', data);
      if (data.resultCode === '000') {
        // 차량이력 조회 성공
        console.log(
          'currentValues.licensePlateNumber',
          currentValues.licensePlateNumber
        );
        setImageString(data.img);
      } else {
        // 차량이력 조회 실패
        setHistoryResultMsg(
          currentValues.licensePlateNumber
            ? `${currentValues.licensePlateNumber} 차량`
            : '[차량번호 없음]'
        );
      }
      setHistoryLoading(false);
    } catch (error) {
      setHistoryLoading(false);
      console.log(error);
      window.cerp.toast.warn(error.message);
    }
  };

  const getNiceDNRDatas = async () => {
    setLoading(true);
    try {
      const data = await RepairApi.getCenter(
        currentValues.licensePlateNumber,
        currentValues.vehicleInformation?.FRST_REGIST_DE
      );
      const result = {};
      data.forEach((data) => {
        const { inDay, outDay, corrWorkName, workName } = data;

        if (!result[inDay]) {
          result[inDay] = {
            inDay,
            outDay,
            workList: [],
          };
        }

        const work =
          workName === corrWorkName
            ? workName
            : `[${corrWorkName}] ${workName}`;
        result[inDay].workList.push(work);
      });
      setAccidentHistory((ps) => ({ ...ps, niceDNR: result }));
      setLoading(false);
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error.message);
      setLoading(false);
    }
  };
  const [imageString, setImageString] = useState('');
  const [encodingImageSrc, setEncodingImageSrc] = useState('');
  const [historyResultMsg, setHistoryResultMsg] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);

  // 메리츠보험이력 조회 base64이미지 디코딩
  useEffect(() => {
    if (imageString.length === 0) return;
    const byteCharacters = atob(imageString);
    // console.log('byteCharacters', byteCharacters);
    const byteNumbers = Array.from(byteCharacters).map((char) =>
      char.charCodeAt(0)
    );
    // console.log('byteNumbers', byteNumbers);
    const byteArray = new Uint8Array(byteNumbers);
    // console.log('byteArray', byteArray);
    const blob = new Blob([byteArray], { type: 'image/png' }); // (array, options)
    // console.log('blob', blob);
    const blobUrl = URL.createObjectURL(blob);
    // console.log('blobUrl', blobUrl);

    setEncodingImageSrc(blobUrl);

    return () => URL.revokeObjectURL(blobUrl);
  }, [imageString]);

  const getDetailData = (whole, baseSubmitData) => {
    const detail = {};

    const keys = Object.keys(baseSubmitData);
    const expectionKeys = [
      'vinRemain',
      'cnmDetail',
      'madeAt',
      'detailedModelType',
      'displacement',
      'maxPerson',
      'STMD_AT',
      'GRBX_KND_NM',
      'MTRS_FOM_NM',
      'USE_FUEL_NM',
      'INSPT_VALID_PD',
      'PRPOS_CHANGE_SE_AT',
      'LATEST_TRVL_DSTNC',
      'CNTC_RESULT_CODE',
      'CNTC_RESULT_DTLS',
      'RCEPT_GRC_CODE',
      'RCEPT_DE',
      'RCEPT_SN',
      'RCEPT_UPD_SN',
      'VHMNO',
    ];

    const convertType = [
      'CRMN_MESURE_VALUE',
      'HYDR_MESURE_VALUE',
      'SMOKE_MESURE_VALUE',
      'PRYE',
      'TRVL_DSTNC',
    ];

    for (let field in whole) {
      if (!keys.includes(field) && !expectionKeys.includes(field)) {
        detail[field] = whole[field];
      }

      if (convertType.includes(field)) {
        detail[field] = String(whole[field]);
      }
    }

    // console.log(
    //   '전체 데이터',
    //   whole,
    //   'base submit data',
    //   baseSubmitData,
    //   '작업 완료된 detail',
    //   detail
    // );

    return detail;
  };

  //ANCHOR - 작성완료
  const onSubmit = async (submitType = '', formData) => {
    const baseSubmitData = {
      licensePlateNumber: _.get(formData, 'licensePlateNumber'),
      association: _.get(formData, 'association'),
      enterprise: _.get(formData, 'enterprise'),
      shop: _.get(formData, 'shop'),
      inspector: _.get(formData, 'inspector'),
      customerCompany: _.get(formData, 'customerCompany'),
      customer: _.get(formData, 'customer'),
      vehicleMaintenance: _.get(formData, 'vehicleMaintenance'),
      vehicleInformation: _.get(formData, 'vehicleInformation'),
      paymentInformation: _.get(formData, 'paymentInformation'),
    };

    baseSubmitData.vehicleInformation.TRVL_DSTNC = currentValues.TRVL_DSTNC;
    if (manualInput) {
      baseSubmitData.vehicleInformation.VHRNO =
        currentValues.licensePlateNumber;
      baseSubmitData.vehicleInformation.TRVL_DSTNC =
        currentValues.vehicleInformation.TRVL_DSTNC;
      baseSubmitData.vehicleInformation.TRVL_DSTNC_STTUS_CODE = getDistanceCode(
        currentValues.vehicleInformation.TRVL_DSTNC,
        currentValues.vehicleInformation.PRYE
      );
      baseSubmitData.vehicleInformation.INSPT_VALID_PD = `${currentValues.vehicleInformation.INSPT_VALID_PD_BGNDE}~${currentValues.vehicleInformation.INSPT_VALID_PD_ENDDE}`;
      baseSubmitData.vehicleInformation.MTRS_FOM_NM =
        currentValues.vehicleInformation.MTRS_FOM;
      baseSubmitData.vehicleInformation.USE_FUEL_NM = baseValueItems[
        'USE_FUEL_CODE'
      ].find(
        (el) => el.value === currentValues.vehicleInformation.USE_FUEL_CODE
      )?.name[0];
    }

    if (!baseSubmitData.vehicleInformation.GRBX_KND_NM) {
      const findItem = baseValueItems['GRBX_KND_CODE'].find(
        (el) => el.value === currentValues.vehicleInformation.GRBX_KND_CODE
      );
      baseSubmitData.vehicleInformation.GRBX_KND_NM = findItem?.label;
    }

    console.log(
      'payment',
      baseSubmitData.paymentInformation,
      'vehicle',
      baseSubmitData.vehicleInformation
    );
    // return;
    setLoading(true);

    window.cerp.toast.showStickyToast('성능 점검 데이터를 전송하고 있습니다.');

    try {
      const { data: baseData } = await onSubmitBase(submitType, baseSubmitData);
      if (baseData) {
        const {
          performanceCheckData: {
            dataId: newDataId,
            statementNumber: newStatementNumber,
          },
        } = baseData;
        if (_.get(currentValues, 'note') !== '') {
          await onSubmitNote(
            newDataId,
            newStatementNumber,
            _.get(currentValues, 'note')
          );
        }
        const { data: detailData, error: detailError } = await onSubmitDetail(
          submitType,
          getDetailData(
            !manualInput
              ? {
                  ...formData,
                  ASSRNC_TY_SE_CODE: _.get(
                    formData,
                    'paymentInformation.ASSRNC_TY_SE_CODE'
                  ),
                  ICNY_CODE:
                    _.get(formData, 'paymentInformation.ICNY_CODE') ?? '',
                }
              : {
                  ...formData,
                  ASSRNC_TY_SE_CODE: _.get(
                    formData,
                    'paymentInformation.ASSRNC_TY_SE_CODE'
                  ),
                  ICNY_CODE:
                    _.get(formData, 'paymentInformation.ICNY_CODE') ?? '',
                  VHRNO: currentValues.licensePlateNumber,
                },
            baseSubmitData
          ),
          newDataId
        );

        if (detailData) {
          const { molitResultCode } = detailData;
        }
        if (detailError) {
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      window.cerp.toast.clear();
      setLoading(false);
      setManualInput(false);
    }
  };

  const onSubmitBase = async (submitType = '', formData) => {
    try {
      console.log('[BASE SUBMIT 데이터]', {
        submitType,
        ...formData,
      });

      // base submit 종류
      // 1. recheck -> registerRecheckBase
      // 2. 신규 등록 -> registerBase
      // 3. 기존 성능 수정 -> updateBase

      const { data } =
        statementNumber === undefined
          ? await performanceCheckService.registerBase({
              submitType,
              ...formData,
            })
          : await performanceCheckService.updateBase({
              submitType,
              dataId: Number(dataId),
              ...formData,
              // detailFlag: 'N',
            });

      return { data };
    } catch (error) {
      console.log('[성능 점검 BASE ERROR]', error);
      if (!error) {
        setTimeout(() => {
          window.cerp.dialog.error(
            `성능 점검 BASE ERROR`,
            '[ERROR CODE 422] 시스템 관리자에게 문의하세요.'
          );
        }, 350);
        return { error };
      }
      setTimeout(() => {
        window.cerp.dialog.error(
          '성능점검 기본 정보 등록 실패',
          `[${error?.code}] ${error?.message}`
        );
      }, 350);
      return { error };
    }
  };

  const onSubmitDetail = async (submitType = '', formData, dataId) => {
    const performanceStatus =
      performanceCheckUtil.PerformanceStatus.convertForServer(formData);
    const partStatus =
      performanceCheckUtil.PartStatus.convertForServer(formData);
    const { fileInfo, files } =
      performanceCheckUtil.File.convertForServer(fileItems);

    // console.log('[detail submit]', formData);

    const requestData = {
      dataId,
      submitType,
      checkData: {
        ...formData,
        performanceStatus,
        partStatus,
        VIN:
          _.get(formData, 'VIN') +
          _.get(currentValues, 'vehicleInformation.vinRemain'),
        UPD_CODE:
          statementNumber === undefined
            ? TransferState.Transfered
            : TransferState.Updated,
        FRST_REGIST_DE: _.get(currentValues, 'FRST_REGIST_DE')
          ? _.get(currentValues, 'FRST_REGIST_DE')
          : dayjs().format('YYYYMMDD'),
        CHCK_DE: _.get(currentValues, 'CHCK_DE')
          ? _.get(currentValues, 'CHCK_DE')
          : dayjs().format('YYYYMMDD'),
        CRMN_MESURE_VALUE:
          _.get(formData, 'CRMN_MESURE_VALUE') === ''
            ? '0.0'
            : _.get(formData, 'CRMN_MESURE_VALUE'),
        HYDR_MESURE_VALUE:
          _.get(formData, 'HYDR_MESURE_VALUE') === ''
            ? '0'
            : _.get(formData, 'HYDR_MESURE_VALUE'),
        SMOKE_MESURE_VALUE:
          _.get(formData, 'SMOKE_MESURE_VALUE') === ''
            ? '0'
            : _.get(formData, 'SMOKE_MESURE_VALUE'),
      },
      fileData: fileInfo,
    };

    setLoading(true);

    try {
      const { data } = await performanceCheckService.registerDetail(
        requestData,
        files
      );

      const { molitState, performanceCheckData } = data;
      const responseData = JSON.parse(performanceCheckData.latestResponseData);

      if (submitType === SubmitType.Complete) {
        if (
          molitState === MolitState.NonTarget ||
          molitState === MolitState.Success
        ) {
          history.replace('/performance-check/form');

          reset(defaultValues);
          setCodes({});
          setWorkDetails([]);
          setFileItems([]);

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          setTimeout(() => {
            window.cerp.toast.success(
              '성능점검 등록 완료',
              `성능점검번호 [${performanceCheckData.statementNumber}]가 등록되었습니다.`
            );
          }, 350);
        } else if (
          molitState === MolitState.Failed ||
          molitState === MolitState.Retry
        ) {
          setTransferError({
            code: responseData?.msgcode
              ? responseData?.msgcode
              : responseData?.cntc_result_code,
            details: responseData?.msg
              ? responseData?.msg
              : responseData?.cntc_result_dtls,
          });
        } else if (requestData?.cntc_result_code !== 'MSG50000') {
          if (
            requestData.cntc_result_code === '199' ||
            requestData.cntc_result_code === 199
          ) {
            setTransferError({
              code: responseData?.cntc_result_code,
              details: '인트라밴 서비스 오류',
            });
          } else {
            setTransferError({
              code: responseData?.cntc_result_code,
              details: responseData?.cntc_result_dtls,
            });
          }
        }
      } else if (submitType === SubmitType.Temp) {
        reset(defaultValues);
        setCodes({});
        setWorkDetails([]);
        setFileItems([]);

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        history.replace('/performance-check/form');

        setTimeout(() => {
          window.cerp.toast.success(
            '성능점검 임시저장 완료',
            `성능점검 [${performanceCheckData.statementNumber}]가 임시저장 되었습니다.`
          );
        }, 350);
      }
      return { data };
    } catch (error) {
      if (submitType === SubmitType.Complete) {
        setTimeout(() => {
          window.cerp.dialog.error(
            '성능점검 등록 실패',
            `[${error?.code}]${error?.message}`
          );
        }, 350);
      } else if (submitType === SubmitType.Temp) {
        setTimeout(() => {
          window.cerp.dialog.error(
            '성능점검 임시저장 실패',
            `[${error?.code}]${error?.message}`
          );
        }, 350);
      }
      reset(defaultValues);
      setCodes({});
      setWorkDetails([]);
      setFileItems([]);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

      history.replace('/performance-check/form');
      return { error };
    }
  };

  async function onSubmitNote(dataId, statementNumber, note) {
    try {
      const { data } = await performanceCheckService.updateNote({
        dataId: Number(dataId),
        statementNumber,
        note,
      });

      const {
        performanceCheckData: { note: updatedNote },
      } = data;

      setValue('note', updatedNote);
    } catch (error) {
      console.log(error);
    }
  }

  const getFormErrorMessage = (name) => {
    const error = _.get(errors, name);
    return <small className="p-error">{error?.message}</small>;
  };

  function setDetailedModelType({
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    // fuelCode = _.get(currentValues, 'vehicleInformation.USE_FUEL_CODE'),
    vehicleInformation = _.get(currentValues, 'vehicleInformation'),
    displace = _.get(currentValues, 'vehicleInformation.displacement'),
    maxPerson = _.get(currentValues, 'vehicleInformation.maxPerson'),
  }) {
    //* filtering 기준
    const filteredOptions = [];
    // 사용할 곳 -> 선택한 보험사의 상품에서 필드값 찾아서 update payment information
    //1. lvl1 : vehicleInformation.madeAt
    //2. lvl2 : vehicleInformation.VHCTY_ASORT_CODE
    const conditions = {
      lvl1: madeAt,
      lvl2: assortType,
    };

    // 2. 전기차나 수소차인 경우에 전기차/수소차 라벨로 책정
    if (
      performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
      performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
    ) {
      conditions.label = '전기차/수소차';

      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));
      setDetailedModelTypeOptions(filteredOptions);

      if (filteredOptions[0]) {
        setValue(
          'vehicleInformation.detailedModelType',
          filteredOptions[0]?.value
        );
      }
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-4. 이륜, 기타의 경우 보험료를 어떻게 책정해야 하는지?
    if (assortType === '5' || assortType === '9') {
      // ---> 보험 상품 이용이 안되는 거면 자가보증으로 바꾸고 보험사 보증 선택할 수 없도록 보험료 업데이트 되지 않도록 픽스 해버리나?
      // 상세 차종은 해당 없음으로 돌려버리고
      // 점검료는 별도로 선택하도록 함
      const option = {
        lvl1: madeAt,
        lvl2: assortType,
        label: '이륜/기타',
        value: '',
      };
      filteredOptions.push(option);
      setDetailedModelTypeOptions(filteredOptions);

      setValue('vehicleInformation.detailedModelType', option.value);
      // return;
      return _.get(filteredOptions, '0.value');
    }

    // 3-3. 화물/특수의 경우 유저가 직접 선택해야 함
    if (assortType === '3' || assortType === '4') {
      filteredOptions.push(..._.filter(VEHICLE_TYPE_LEVEL3, conditions));

      setDetailedModelTypeOptions(filteredOptions);
      const detailModel = isRefresh
        ? isEditFormValues.detailedModelType
        : filteredOptions[2]?.value;
      setValue('vehicleInformation.detailedModelType', detailModel);
      return detailModel;
    }

    // 필터링 완료 후 보여줄 옵션 업데이트
    // 선택이 가능한 경우 상세 차종 값도 즉시 업데이트
    // 선택이 불가능한 경우 보여줄 값 지정하기

    // 1. 국산 혹은 수입
    // 3. 차종 구분 - 승용, 승합 ... 등등

    // 3-1. 차종이 승용인 경우 배기량 입력에 따라 라벨 자동 책정되도록 함
    // 3-2. 승합인 경우 승차인원 입력에 따라 라벨 자동 책정되도록 함
    if (assortType === '1' || assortType === '2') {
      // vehicle list에서 filter 함수에서 참인 것만 뽑아내야함
      const items =
        _.filter(VEHICLE_TYPE_LEVEL3, {
          ...conditions,
        }) || [];
      const option = _.find(items, (item) => {
        return item.filter(displace, Number(maxPerson), vehicleInformation);
      });

      // filteredOptions.push(option);
      // setDetailedModelTypeOptions(filteredOptions);

      filteredOptions.push(...items);
      setDetailedModelTypeOptions(filteredOptions);

      if (_.get(option, 'value')) {
        setValue(
          'vehicleInformation.detailedModelType',
          _.get(option, 'value')
        );
        return _.get(option, 'value');
      }

      setValue(
        'vehicleInformation.detailedModelType',
        filteredOptions[0]?.value
      );
      return _.get(filteredOptions, '0.value');
    }
  }

  function getFileURL(photoGroupId, photo) {
    if (photoGroupId.indexOf('KAT') === 0) {
      return _.get(photo, 'filePath');
    }
    return process.env.REACT_APP_S3_BASE_URL + _.get(photo, 'filePath');
  }

  const separateUserData = (userData) => {
    const inspectorSteps = {
      association: _.pick(userData, [
        'associationId',
        'associationCode',
        'associationName',
      ]),
      enterprise: _.pick(userData, [
        'enterpriseId',
        'enterpriseCode',
        'enterpriseName',
      ]),
      shop: _.pick(userData, ['shopId', 'shopCode', 'shopName']),
    };

    return [inspectorSteps, userData];
  };

  const separateCustomerData = (customerData) => {
    const customerCompany = {
      ..._.pick(customerData, [
        'unionId',
        'complexId',
        'mainPhone',
        'companyName',
        'note',
        'customerCompanyId',
        'businessRegistrationNumber',
      ]),
    };

    const customer = {
      ..._.pick(customerData, [
        'contact',
        'customerId',
        'customerName',
        'customerCompanyId',
        'note',
      ]),
    };

    return [customerCompany, customer];
  };

  const checkAbnormalPart = ({
    currentValues,
    lossStatus = _.get(currentValues, 'vehicleInformation.LOSS_STATUS'),
    accidentUpdateRequest = false,
  }) => {
    let repairCount = 0;
    let accidentCount = 0;
    const isLoss = lossStatus === YN.YES;

    PARTS_ALL.forEach(({ partPoint }) => {
      if (REPAIR_STATUS[partPoint]) {
        if (REPAIR_STATUS[partPoint].includes(currentValues[partPoint])) {
          repairCount++;
        }
      }

      if (ACCIDENT_STATUS[partPoint]) {
        if (ACCIDENT_STATUS[partPoint].includes(currentValues[partPoint])) {
          accidentCount++;
        }
      }
    });

    // '교환, 판금 등 이상 부위' 체크 시에 '사고이력', '단순수리여부' 자동 업데이트 기능
    setValue(
      'ACDNT_AT',
      accidentCount > 0 || isLoss || accidentUpdateRequest ? '1' : '0'
    );
    setValue('SIMPL_REPAIR_AT', repairCount > 0 ? '1' : '0');
  };

  function handleGasMesureValues(vehicleInformation) {
    if (performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation)) {
      setValue('CHK_CRMN_MESURE_VALUE', 'N');
      setValue('CHK_HYDR_MESURE_VALUE', 'N');
      setValue('CHK_SMOKE_MESURE_VALUE', 'N');
    } else if (performanceCheckUtil.Checker.Fuel.isDiesel(vehicleInformation)) {
      setValue('CHK_CRMN_MESURE_VALUE', 'N');
      setValue('CHK_HYDR_MESURE_VALUE', 'N');
      setValue('CHK_SMOKE_MESURE_VALUE', 'Y');
    } else {
      setValue('CHK_CRMN_MESURE_VALUE', 'Y');
      setValue('CHK_HYDR_MESURE_VALUE', 'Y');
      setValue('CHK_SMOKE_MESURE_VALUE', 'N');
    }
  }

  const checkFrameStatus = (previousValues, currentValues) => {
    const diff = Diff.diff(previousValues, currentValues);
    const ppDiff = _.reduce(
      diff,
      (res, v, k) => {
        if (_.startsWith(k, 'pp_')) res[k] = v;
        return res;
      },
      {}
    );
    if (_.isEmpty(ppDiff)) return;
    const partPoint = _.keys(ppDiff)[0];
    const value = ppDiff[partPoint];
    if (value === '' || value === undefined) return;
    const thisPart = PARTS_ALL.find((p) => p.partPoint === partPoint);
    const { dataKey, partNumber, partSubNumber } = thisPart;
    const otherParts = PARTS_ALL.filter(
      (p) => p.partPoint !== partPoint && p.partNumber === partNumber
    );

    let finalValue = _.toNumber(value);
    for (let { partPoint } of otherParts) {
      let partValue = _.toNumber(currentValues[partPoint]) || 1;
      if (partValue === 1) continue;
      if (finalValue === 1 || finalValue > partValue) {
        finalValue = partValue;
      }
    }
    finalValue = _.toString(finalValue);

    // 필러패널 좌-우 part에 대한 업데이트면 D14A ~ D14C 값을 value 그대로 업데이트함
    if (partNumber === '14' && partSubNumber !== '') {
      setValue(`D${partNumber}${partSubNumber}`, value);
    }

    // s~ 데이터 값을 정리된 finalValue로 업데이트
    setValue(dataKey, finalValue);
  };

  const getFuelCode = (fuelName) => {
    const item =
      fuelName === ''
        ? _.find(baseValueItems['USE_FUEL_CODE'], { label: '기타' })
        : _.find(baseValueItems['USE_FUEL_CODE'], (item) => {
            return _.indexOf(item.name, fuelName) >= 0;
          });

    if (item) {
      return item.value;
    }

    return _.get(baseValueItems, 'USE_FUEL_CODE.0.value');
  };

  const getGrbxCode = (grbxName) => {
    for (let item of baseValueItems['GRBX_KND_CODE']) {
      if (_.startsWith(grbxName, item.label)) {
        return item.value;
      }
    }

    return _.get(baseValueItems, 'GRBX_KND_CODE.0.value');
  };

  const getFromTo = (period) => {
    const [from, to] = _.split(period, '~');
    return [from, to];
  };

  const getDistance = (distance) => {
    if (distance === '') return 0;
    return distance;
  };

  //SECTION - 차량 정보 조회
  async function getCarInformation() {
    setLoading(true);
    setCarInformationLoading(true);

    const licensePlateNumber = _.get(currentValues, 'licensePlateNumber');
    try {
      const {
        data: {
          molitData: {
            RECORD1: [rawVehicleInformation],
            RECORD2: vehicleMaintenances,
          },
          workDetails,
        },
      } = await molitService.vehicleInformation([licensePlateNumber]);

      const { VIN } = rawVehicleInformation;
      let madeAt = _.startsWith(VIN, 'K') ? 'D' : 'F';

      const findItem = MADE_AT_D.find((el) =>
        rawVehicleInformation['CNM'].includes(el.value)
      );
      if (findItem) {
        madeAt = 'D';
      }
      const fuelName = rawVehicleInformation['USE_FUEL_NM'] || '';
      const motorName = rawVehicleInformation['MTRS_FOM_NM'] || '-';
      const fuelCode = getFuelCode(fuelName);
      const grbxName = rawVehicleInformation['GRBX_KND_NM'];
      const grbxCode = getGrbxCode(rawVehicleInformation['GRBX_KND_NM']);
      const officialDistance = isEditForm
        ? isEditFormValues.TRVL_DSTNC
        : getDistance(rawVehicleInformation['TRVL_DSTNC']) || 0;
      const distanceCode = getDistanceCode(
        officialDistance,
        _.get(rawVehicleInformation, 'PRYE')
      );
      const [startDate, endDate] = getFromTo(
        rawVehicleInformation['INSPT_VALID_PD']
      );
      const maxPerson = isEditForm
        ? isEditFormValues.maxPerson
        : rawVehicleInformation['maxPerson']
        ? Number(rawVehicleInformation['maxPerson'])
        : 0;
      const displacement = isEditForm
        ? isEditFormValues.displacement
        : rawVehicleInformation['displacement'];
      const newVehicleInformation = {
        ...currentValues['vehicleInformation'],
        ...rawVehicleInformation,
        USE_FUEL_CODE: fuelCode,
        GRBX_KND_NM: grbxName,
        GRBX_KND_CODE: grbxCode,
        LATEST_TRVL_DSTNC: officialDistance,
        TRVL_DSTNC: officialDistance,
        TRVL_DSTNC_STTUS_CODE: distanceCode,
        INSPT_VALID_PD_BGNDE: startDate,
        INSPT_VALID_PD_ENDDE: endDate,
        MTRS_FOM: motorName,
        MTRS_FOM_NM: motorName,
        USE_FUEL_NM: fuelName,
        STMD_AT: rawVehicleInformation['STMD_AT'],
        PRPOS_CHANGE_SE_AT: rawVehicleInformation['PRPOS_CHANGE_SE_AT'],
        madeAt: madeAt,
        maxPerson: maxPerson,
        displacement: displacement,
      };
      handlePartDefaultValues({
        USE_FUEL_CODE: fuelCode,
        GRBX_KND_NM: grbxName,
        GRBX_KND_CODE: grbxCode,
      });

      setValue('vehicleInformation', newVehicleInformation);

      /*  변속기 종류 디테일 나중에 값 입력하는 Input 추가하기 */
      setValue('GRBX_KND_DTLS', '');
      setValue('CNM', newVehicleInformation['CNM']);
      setValue('MTRS_FOM', newVehicleInformation['MTRS_FOM']); // 원동기 형식
      setValue('VIN', newVehicleInformation['VIN']);
      setValue('PRYE', newVehicleInformation['PRYE']);
      setValue('VHRNO', newVehicleInformation['VHRNO']);
      setValue('FRST_REGIST_DE', newVehicleInformation['FRST_REGIST_DE']); // 최초 등록일
      setValue('STMD_AT', newVehicleInformation['STMD_AT']);
      setValue(
        'PRPOS_CHANGE_SE_AT',
        newVehicleInformation['PRPOS_CHANGE_SE_AT']
      );

      setValue('LATEST_TRVL_DSTNC', officialDistance);
      // setValue('TRVL_DSTNC', officialDistance);
      setValue('TRVL_DSTNC_STTUS_CODE', distanceCode);
      setValue('INSPT_VALID_PD_BGNDE', startDate); // 검사 유효 시작일
      setValue('INSPT_VALID_PD_ENDDE', endDate); // 검사 유효 만료일
      setValue('GRBX_KND_NM', grbxName);
      setValue('GRBX_KND_CODE', grbxCode);
      setValue('USE_FUEL_CODE', fuelCode);

      if (
        performanceCheckUtil.Checker.Fuel.isElectronic(newVehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(newVehicleInformation)
      ) {
        const updateETC =
          '[전기차량으로 배출가스 및 내연기관차량 점검항목 점검불가]';
        setValue('ETC_MATTER', currentValues['ETC_MATTER'] + updateETC);
        // setValue('ENGINE_STBLT_AT', '');
        // setValue('GRBX_STBLT_AT', '');
      }

      if (
        performanceCheckUtil.Checker.GearBox.isManual(newVehicleInformation)
      ) {
        if (shopCode === 'S32') {
          //NOTE - 팀모터스 제외
          setValue('GRBX_STBLT_AT', '1');
        } else {
          setValue('GRBX_STBLT_AT', '');
        }
        setValue('s016', '');
      }

      handleGasMesureValues(newVehicleInformation);

      const detailModel = setDetailedModelType({
        madeAt: madeAt,
        vehicleInformation: newVehicleInformation,
        maxPerson: maxPerson,
        displace: displacement,
      });
      setValue('vehicleMaintenance', vehicleMaintenances);
      setWorkDetails(workDetails);
      setLoading(false);
      setSearchFailed(false);
      handleSubmit((formData) => console.log('formData', formData))();
      return [_.get(newVehicleInformation, 'TRVL_DSTNC'), madeAt, detailModel];
    } catch (error) {
      setLoading(false);
      if (!licensePlateNumber) {
        window.cerp.dialog.error(
          '차량조회 실패',
          `[EMPTY] 차량 번호를 입력하세요.`
        );
        // return;
        return [0, 0, 0];
      }
      setSearchFailed(true);
      window.cerp.dialog.error(
        '차량조회 실패',
        `[${error?.code}] ${error?.message}`
      );
      return [0, 0, 0];
    } finally {
      setLoading(false);
      setCarInformationLoading(false);
    }
  }

  function getDistanceCode(distance, releaseYear) {
    // 연수 구하기 : 현재 년도 - 첫 등록 년도
    const years = new Date().getFullYear() - releaseYear;
    // 기준값은 연당 20000
    const standard = years * 20000;
    // 오차 범위 기준
    const gap = 10000;
    const differ = standard - distance;
    // 적음 : 0 < standard - distance < 10000
    if (differ > gap) {
      return '1';
    }
    // 보통 : 10000 <= standard - distance <= 0
    if (differ >= 0 && differ <= gap) {
      return '2';
    }
    // 많음 : standard - distance < 0
    if (differ < 0) {
      return '3';
    }
  }

  function clearCheckPrice() {
    setValue('paymentInformation.checkPriceTableId', 0);
    setValue('paymentInformation.checkPriceItemId', '');
    setValue('paymentInformation.checkPrice', 0);
    setValue('paymentInformation.checkPriceVAT', 0);
  }

  function getCurrentCheckPrice(data) {
    const {
      checkPriceTable,
      paymentInformation: { checkPriceItemId: selectedItemId },
    } = data;
    const { checkPriceItems } = checkPriceTable || {};

    if (checkPriceItems) {
      const itemId = selectedItemId || _.get(checkPriceItems, '0.id');
      const item = _.find(checkPriceItems, { id: itemId });

      const currentTotalCheckPrice = _.toNumber(_.get(item, 'price'));
      const currentCheckPriceVAT =
        Math.floor(currentTotalCheckPrice / 11 / 10) * 10;
      const currentCheckPrice = currentTotalCheckPrice - currentCheckPriceVAT;

      setCurrentPrices((ps) => ({
        ...ps,
        totalCheckPrice: currentTotalCheckPrice,
        checkPriceVAT: currentCheckPriceVAT,
        checkPrice: currentCheckPrice,
      }));
    }
  }

  function getCurrentInsurancePrice(data) {
    const { insurancePriceTables, paymentInformation } = data;

    const insuranceCompany =
      _.get(currentValues, 'paymentInformation.ICNY_CODE') ||
      _.get(_.get(insurancePriceTables, '0'), 'insuranceCompany');
    const currentPriceTable = _.find(insurancePriceTables, {
      insuranceCompany,
    });

    if (currentPriceTable) {
      const previousTotalInsurancePrice =
        _.get(paymentInformation, 'insurancePrice') +
        _.get(paymentInformation, 'insurancePriceVAT');
      const previousInsurancePriceVAT =
        Math.floor(previousTotalInsurancePrice / 11 / 10) * 10;
      const previousInsurancePrice =
        previousTotalInsurancePrice - previousInsurancePriceVAT;

      console.log(previousTotalInsurancePrice, '이전 원래 보험료');
      setCurrentPrices((ps) => ({
        ...ps,
        totalInsurancePrice: previousTotalInsurancePrice,
        insurancePrice: previousInsurancePrice,
        insurancePriceVAT: previousInsurancePriceVAT,
      }));
    }
  }

  function setCheckPrice({
    checkPriceTable = codes.checkPriceTable,
    selectedItemId = _.get(
      currentValues,
      'paymentInformation.checkPriceItemId'
    ),
    discountedCheckPrice = _.get(
      currentValues,
      'paymentInformation.discountedCheckPrice'
    ),
  } = {}) {
    const { checkPriceOf, checkPriceTableId, checkPriceItems } =
      checkPriceTable || {};

    if (checkPriceItems) {
      // 1. 국산/수입 여부 알 수 있다면 국산/수입 테이블에서 첫번째 id로 기본값 세팅
      // 2. 국산/수입 여부 알 수 없다면 테이블 중 첫번째 id로 기본값 세팅
      const madeAt = currentValues.vehicleInformation.madeAt;
      const itemId = madeAt
        ? madeAt === 'F'
          ? _.get(_.find(checkPriceItems, { priceType: 'F' }), 'id')
          : _.get(_.find(checkPriceItems, { priceType: 'D' }), 'id')
        : selectedItemId || _.get(checkPriceItems, '0.id');
      const item = _.find(checkPriceItems, { id: itemId });
      if (!_.get(currentValues, 'paymentInformation.checkPriceItemId')) {
        setValue('paymentInformation.checkPriceItemId', itemId);
      }

      const previousTotalCheckPrice = 0;
      const previousCheckPrice = 0;
      const previousCheckPriceVAT = 0;

      const currentTotalCheckPrice = _.toNumber(_.get(item, 'price'));

      const currentCheckPriceVAT =
        Math.floor(currentTotalCheckPrice / 11 / 10) * 10;
      const currentCheckPrice = currentTotalCheckPrice - currentCheckPriceVAT;

      setCurrentPrices((ps) => ({
        ...ps,
        totalCheckPrice: currentTotalCheckPrice,
        checkPriceVAT: currentCheckPriceVAT,
        checkPrice: currentCheckPrice,
      }));

      const isMoreExpensive =
        currentTotalCheckPrice - previousTotalCheckPrice >= 0;

      const checkPrice = isMoreExpensive
        ? currentCheckPrice - previousCheckPrice
        : 0;
      const checkPriceVAT = isMoreExpensive
        ? currentCheckPriceVAT - previousCheckPriceVAT
        : 0;
      const totalCheckPrice = isMoreExpensive
        ? currentTotalCheckPrice - previousTotalCheckPrice
        : 0;

      setValue('paymentInformation.checkPriceTableId', checkPriceTableId);
      setValue(
        'paymentInformation.checkPrice',
        _.isNaN(checkPrice) ? 0 : checkPrice
      );
      setValue(
        'paymentInformation.checkPriceVAT',
        _.isNaN(checkPriceVAT) ? 0 : checkPriceVAT
      );

      if (
        _.isNaN(totalCheckPrice) ||
        totalCheckPrice - discountedCheckPrice <= 0
      ) {
        const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
        setValue('paymentInformation.checkPricePaidAt', current);
        setValue('paymentInformation.checkPricePaidYN', YN.YES);
      } else {
        setValue('paymentInformation.checkPricePaidAt', null);
        setValue('paymentInformation.checkPricePaidYN', YN.NO);
      }

      return [totalCheckPrice, checkPriceVAT, checkPrice];
    } else {
      clearCheckPrice();
      return [0, 0, 0];
    }
  }

  function clearInsurancePrice() {
    setValue('paymentInformation.ICNY_CODE', '');
    setValue('paymentInformation.insurancePriceTableId', 0);
    setValue('paymentInformation.insurancePrice', 0);
    setValue('paymentInformation.insurancePriceVAT', 0);
    setValue('paymentInformation.insurancePricePaidAt', null);
    setValue('paymentInformation.insurancePricePaidYN', YN.NO);
    setValue('paymentInformation.insurancePricePaidBy', 'TRAN');
    return [0, 0, 0];
  }

  const getCarTypeCode = ({
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    detailModel = _.get(currentValues, 'vehicleInformation.detailedModelType'),
  }) => {
    return `${madeAt}${assortType}${detailModel}`;
  };

  function setInsurancePrice({
    distance = _.get(currentValues, 'TRVL_DSTNC'),
    warrantyType = _.get(currentValues, 'paymentInformation.ASSRNC_TY_SE_CODE'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    detailModel = _.get(currentValues, 'vehicleInformation.detailedModelType'),
    insurancePriceTables = _.get(codes, 'insurancePriceTables'),
  } = {}) {
    const carTypeCode = getCarTypeCode({ madeAt, assortType, detailModel });
    const suffix = performanceCheckUtil.Distance.getInsuranceSuffix(distance);

    if (suffix === 'X' || warrantyType === '1') {
      clearInsurancePrice();
      return [0, 0, 0];
    } else if (assortType === '5' || assortType === '9') {
      // 이륜이나 기타일때
      const current = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      setValue('paymentInformation.ICNY_CODE', '');
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
      setValue('ASSRNC_TY_SE_CODE', '1');
      setValue('paymentInformation.insurancePricePaidAt', current);
      setValue('paymentInformation.insurancePricePaidYN', 'Y');
      // console.log('[이륜 혹은 기타]');
      return [0, 0, 0];
    } else {
      const insuranceCompany =
        _.get(currentValues, 'paymentInformation.ICNY_CODE') ||
        _.get(_.get(insurancePriceTables, '0'), 'insuranceCompany');
      const currentPriceTable = _.find(insurancePriceTables, {
        insuranceCompany,
      });

      if (currentPriceTable) {
        const { priceTableId, priceTable, insurancePriceOf } =
          currentPriceTable;
        const insurancePriceCode = `${carTypeCode}_${suffix}`;
        console.log('insurancePriceCode ===', insurancePriceCode);
        const currentInsurancePrice =
          _.toNumber(_.get(priceTable, `${carTypeCode}_${suffix}`)) || 0;
        const currentInsurancePriceVAT =
          Math.floor((currentInsurancePrice * 0.1) / 10) * 10;
        const currentTotalInsurancePrice =
          currentInsurancePrice + currentInsurancePriceVAT;

        console.log(
          'cur insurance =>',
          currentInsurancePrice,
          currentInsurancePriceVAT
        );
        setCurrentPrices((ps) => ({
          ...ps,
          totalInsurancePrice: currentTotalInsurancePrice,
          insurancePriceVAT: currentInsurancePriceVAT,
          insurancePrice: currentInsurancePrice,
        }));

        const insurancePrice = currentInsurancePrice;
        const insurancePriceVAT = currentInsurancePriceVAT;
        const totalInsurancePrice = insurancePrice + insurancePriceVAT;

        setValue('paymentInformation.ICNY_CODE', insuranceCompany);
        setValue('paymentInformation.insurancePriceTableId', priceTableId || 0);
        setValue(
          'paymentInformation.insurancePrice',
          _.isNaN(insurancePrice) ? 0 : insurancePrice
        );
        setValue(
          'paymentInformation.insurancePriceVAT',
          _.isNaN(insurancePriceVAT) ? 0 : insurancePriceVAT
        );
        setValue('paymentInformation.insurancePriceCode', insurancePriceCode);
        return [
          totalInsurancePrice,
          insurancePriceVAT,
          insurancePrice,
          insurancePriceCode,
        ];
      } else {
        clearInsurancePrice();
        return [0, 0, 0];
      }
    }
  }

  async function getCheckPriceTable(
    { associationId = 0, enterpriseId = 0, shopId = 0, checkPriceTableId } = {},
    isLocked
  ) {
    let params = {
      associationId,
      enterpriseId,
      shopId,
      checkPriceTableId,
    };
    if (isLocked) params = { ...params };

    const { data: checkPriceTable } = await priceService.getCheckPrice(params);
    console.log(
      '[점검료 조회]',
      associationId,
      enterpriseId,
      shopId,
      checkPriceTable
    );
    return [checkPriceTable];
  }

  async function getInsurancePriceTables(
    {
      associationId = 0,
      enterpriseId = 0,
      shopId = 0,
      insurancePriceOf,
      insurancePriceTableId,
    } = {},
    isLocked
  ) {
    let params = { associationId, enterpriseId, shopId };
    if (isLocked)
      params = { ...params, insurancePriceOf, insurancePriceTableId };

    const { data: insurancePriceTables } =
      await priceService.getInsurancePrices(params);
    const insurances = _.reduce(
      insurancePriceTables,
      (r, v) => {
        r.push({
          label: `[${_.get(v, 'insuranceCompany')}] ${_.get(v, 'companyName')}`,
          value: _.get(v, 'insuranceCompany'),
        });
        return r;
      },
      []
    );
    const insurance = _.get(insurances, 0) || {};
    const value = insurance.value;
    console.log('[보험료 조회]', value, insurances);

    return [insurancePriceTables, value, insurances];
  }

  function calculatePrices({
    checkPrice = _.toNumber(
      _.get(currentValues, 'paymentInformation.checkPrice')
    ),
    checkPriceVAT = _.toNumber(
      _.get(currentValues, 'paymentInformation.checkPriceVAT')
    ),
    insurancePrice = _.toNumber(
      _.get(currentValues, 'paymentInformation.insurancePrice')
    ),
    insurancePriceVAT = _.toNumber(
      _.get(currentValues, 'paymentInformation.insurancePriceVAT')
    ),
  } = {}) {
    const summaryPrice = checkPrice + insurancePrice;
    const vat = checkPriceVAT + insurancePriceVAT;
    const totalPrice = summaryPrice + vat;

    return [totalPrice, summaryPrice, vat];
  }

  function handlePartDefaultValues(updateValues = _.get(currentValues)) {
    const targets = [];
    const panels = [];

    for (let i = 37; i < 57; i++) {
      if (i === 55) continue;
      panels.push(`s0${i}`);
    }

    const entireParts = _.filter(Object.keys(currentValues), (field, key) => {
      return _.startsWith(field, 's0');
    });
    const entirePartsMap = entireParts.reduce((map, obj) => {
      map.set(obj, obj);
      return map;
    }, new Map());

    if (performanceCheckUtil.Checker.GearBox.isManual(updateValues)) {
      const manualParts = ['s017', 's018', 's019', 's020'];
      targets.push(...manualParts);
    } else {
      const automaticParts = ['s016'];
      if (
        !(
          performanceCheckUtil.Checker.Fuel.isElectronic(updateValues) ||
          performanceCheckUtil.Checker.Fuel.isHydrogen(updateValues)
        )
      ) {
        const exceptAutomaticParts = ['s012', 's013'];
        automaticParts.push(...exceptAutomaticParts);
      }
      targets.push(...automaticParts);
    }

    // 유종에 따라
    if (performanceCheckUtil.Checker.Fuel.isGasoline(updateValues)) {
      targets.push(...GASOLINE_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isDiesel(updateValues)) {
      targets.push(...DIESEL_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isLPG(updateValues)) {
      targets.push(...LPG_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isElectronic(updateValues)) {
      targets.push(...ELECTRONIC_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isHydrogen(updateValues)) {
      targets.push(...HYDROGEN_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isHybrid(updateValues)) {
      targets.push(...HYBRID_PARTS);
    }
    if (performanceCheckUtil.Checker.Fuel.isEtc(updateValues)) {
      targets.push(...ETC_PARTS);
    }

    targets.forEach((target) => {
      if (entirePartsMap.get(target) !== undefined) {
        setValue(target, '1');
      } else {
        setValue(target, '');
      }
    });
    handleNkDefaultValue();
  }

  function checkAuth(userInfo, type) {
    const { roleCode } = userInfo;
    // 어드민

    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return (
          r.value === 'CERP_ADM' ||
          r.value === 'A_MST' ||
          r.value === 'E_OFF' ||
          _.startsWith(r.value, 'S_')
        );
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  function checkEwTarget(userInfo) {
    const {
      shopInfo: { shopCode },
      roleCode,
    } = userInfo;

    return (
      _.find(EW_TARGET_SHOPS, { shopCode }) !== undefined ||
      roleCode === 'CERP_ADM'
    );
  }

  function handleNkDefaultValue() {
    if (
      isNewForm &&
      (currentValues.shop?.shopName === '엔케이모터스' ||
        myInfoLoadable?.contents?.shopInfo?.shopName?.includes('엔케이모터스'))
    ) {
      const targets = ['s013', 's023', 's060', 's026', 's062'];
      const entireParts = _.filter(Object.keys(currentValues), (field, key) => {
        return _.startsWith(field, 's0');
      });
      const entirePartsMap = entireParts.reduce((map, obj) => {
        map.set(obj, obj);
        return map;
      }, new Map());

      targets.forEach((target) => {
        if (entirePartsMap.get(target) !== undefined) {
          setValue(target, '');
        }
      });
    }
  }

  useEffect(() => {
    handleNkDefaultValue();
  }, [currentValues?.shop?.shopName]);

  async function handleTempSaveByApp({
    checkPriceTable = _.get(codes, 'checkPriceTable'),
    insurancePriceTables = _.get(codes, 'insurancePriceTables'),
    currentVehicleInformation = _.get(currentValues, 'vehicleInformation'),
    currentPaymentInformation = _.get(currentValues, 'paymentInformation'),
  } = {}) {
    //app 에서 임시저장한 데이터인지 확인
    // console.log('temp setting!');
    const VIN = _.get(currentVehicleInformation, 'VIN');
    const madeAt = _.startsWith(VIN, 'K') ? 'D' : 'F';
    const fuelCode = getFuelCode(currentVehicleInformation['USE_FUEL_NM']);
    const grbxName = currentVehicleInformation['GRBX_KND_NM'];
    const grbxCode = getGrbxCode(currentVehicleInformation['GRBX_KND_NM']);
    const officialDistance = getDistance(
      currentVehicleInformation['TRVL_DSTNC']
    );
    const distanceCode = getDistanceCode(
      officialDistance,
      _.get(currentVehicleInformation, 'PRYE')
    );

    const newVehicleInformation = {
      ...currentVehicleInformation,
      USE_FUEL_CODE: fuelCode,
      GRBX_KND_NM: grbxName,
      GRBX_KND_CODE: grbxCode,
      LATEST_TRVL_DSTNC: officialDistance,
      TRVL_DSTNC: officialDistance,
      TRVL_DSTNC_STTUS_CODE: distanceCode,
      MTRS_FOM: currentVehicleInformation['MTRS_FOM_NM'],
      STMD_AT: currentVehicleInformation['STMD_AT'],
      PRPOS_CHANGE_SE_AT: currentVehicleInformation['PRPOS_CHANGE_SE_AT'],
      madeAt: madeAt,
    };

    const newPaymentInformation = {
      ..._.get(defaultValues, 'paymentInformation'),
      ASSRNC_TY_SE_CODE:
        performanceCheckUtil.Distance.getInsuranceSuffix(
          _.get(newVehicleInformation, 'TRVL_DSTNC')
        ) === 'X'
          ? '1'
          : '2',
      insurancePolicyNumber: _.get(
        currentPaymentInformation,
        'insurancePolicyNumber'
      ),
    };

    if (
      performanceCheckUtil.Distance.getInsuranceSuffix(
        _.get(newVehicleInformation, 'TRVL_DSTNC')
      ) === 'X'
    ) {
      setValue('ASSRNC_TY_SE_CODE', '1');
    }

    setValue('paymentInformation', newPaymentInformation);

    handlePartDefaultValues({
      USE_FUEL_CODE: fuelCode,
      GRBX_KND_NAME: grbxName,
      GRBX_KND_CODE: grbxCode,
    });

    setValue('vehicleInformation', newVehicleInformation);

    setValue('GRBX_KND_DTLS', '');
    setValue('CNM', newVehicleInformation['CNM']);
    setValue('MTRS_FOM', newVehicleInformation['MTRS_FOM_NM']); // 원동기 형식
    setValue('VIN', newVehicleInformation['VIN']);
    setValue('PRYE', newVehicleInformation['PRYE']);
    setValue('FRST_REGIST_DE', newVehicleInformation['FRST_REGIST_DE']); // 최초 등록일
    setValue('STMD_AT', newVehicleInformation['STMD_AT']);
    setValue('PRPOS_CHANGE_SE_AT', newVehicleInformation['PRPOS_CHANGE_SE_AT']);

    setValue('TRVL_DSTNC_STTUS_CODE', distanceCode);
    setValue('GRBX_KND_NAME', grbxName);
    setValue('GRBX_KND_CODE', grbxCode);
    setValue('USE_FUEL_CODE', fuelCode);

    if (
      performanceCheckUtil.Checker.Fuel.isElectronic(newVehicleInformation) ||
      performanceCheckUtil.Checker.Fuel.isHydrogen(newVehicleInformation)
    ) {
      const updateETC =
        '[전기차량으로 배출가스 및 내연기관차량 점검항목 점검불가]';
      setValue('ETC_MATTER', currentValues['ETC_MATTER'] + updateETC);
      // setValue('ENGINE_STBLT_AT', '');
      // setValue('GRBX_STBLT_AT', '');
    }

    if (performanceCheckUtil.Checker.GearBox.isManual(newVehicleInformation)) {
      setValue('GRBX_STBLT_AT', '');
    }

    handleGasMesureValues(newVehicleInformation);

    const detailModel = setDetailedModelType({
      madeAt: madeAt,
      vehicleInformation: newVehicleInformation,
    });

    return [officialDistance, madeAt, detailModel];
  }

  useEffect(() => {
    const checkBlockingCondition = true;

    const unblock = history.block((location, action) => {
      if (action === 'PUSH' && checkBlockingCondition) {
        return window.confirm(
          '작성중인 내용이 사라집니다. 페이지를 나가시겠습니까?'
        );
      }
      return true;
    });

    return () => unblock();
  }, [history]);

  //ANCHOR - 성능점검 정보 조회
  useEffect(() => {
    (async () => {
      setLoading(true);

      if (dataId) {
        try {
          //* 요청을 보낼 땐 parameter에서 받아온 statementNumber
          const { data } = await performanceCheckService.getData(dataId);
          const { performanceCheckData, performanceCheckPhotos, workDetails } =
            data;
          setWorkDetails(workDetails);
          setCurMolitState(data.molitState);
          setCurTransferState(performanceCheckData.transferState);
          //* 요청 응답으로 돌아온 기존 statementNumber
          const {
            // dataId,
            // statementNumber,
            isRecheck,
            licensePlateNumber,
            VHRNO,
            association: associationRaw,
            enterprise: enterpriseRaw,
            shop: shopRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            customer: customerRaw,
            vehicleInformation: vehicleInformationRaw,
            vehicleMaintenance: vehicleMaintenanceRaw,
            paymentInformation: paymentInformationRaw,
            checkData: checkDataRaw,
            fileData: fileDataRaw,
          } = performanceCheckData;
          setIsRecheck(isRecheck === YN.YES);

          const association = JSON.parse(associationRaw);
          const enterprise = JSON.parse(enterpriseRaw);
          const shop = JSON.parse(shopRaw);
          const inspector = JSON.parse(inspectorRaw);
          const customerCompany = JSON.parse(customerCompanyRaw);
          const customer = JSON.parse(customerRaw);
          const vehicleInformation = JSON.parse(vehicleInformationRaw);
          const vehicleMaintenance = JSON.parse(vehicleMaintenanceRaw);
          const paymentInformation = JSON.parse(paymentInformationRaw);
          const checkData = JSON.parse(checkDataRaw);
          const fileData = JSON.parse(fileDataRaw);
          setIsEditFormValues({
            TRVL_DSTNC: checkData?.TRVL_DSTNC,
            displacement: vehicleInformation?.displacement || 0,
            maxPerson: vehicleInformation?.maxPerson || 1,
            detailedModelType: vehicleInformation.detailedModelType,
            VHCTY_ASORT_CODE: vehicleInformation.VHCTY_ASORT_CODE,
          });

          originPaymentRef.current = {
            ...paymentInformation,
            checkPrice: _.get(paymentInformation, 'checkPrice') || 0,
            checkPriceVAT: _.get(paymentInformation, 'checkPriceVAT') || 0,
            insurancePrice: _.get(paymentInformation, 'insurancePrice') || 0,
            insurancePriceVAT:
              _.get(paymentInformation, 'insurancePriceVAT') || 0,
          };
          originVehicleRef.current = vehicleInformation;

          const options = _.filter(VEHICLE_TYPE_LEVEL3, {
            lvl1: _.get(vehicleInformation, 'madeAt'),
            lvl2: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
          });
          setDetailedModelTypeOptions(options);

          /* codes 세팅하기 -> codes 세팅해야 보증업체 끌어올 수 있음.*/
          const [[checkPriceTable], [insurancePriceTables, value, insurances]] =
            await Promise.all([
              getCheckPriceTable({
                associationId: _.get(association, 'associationId'),
                enterpriseId: _.get(enterprise, 'enterpriseId'),
                shopId: _.get(shop, 'shopId'),
                checkPriceTableId: _.get(
                  paymentInformation,
                  'checkPriceTableId'
                ),
              }),
              getInsurancePriceTables({
                associationId: _.get(association, 'associationId'),
                enterpriseId: _.get(enterprise, 'enterpriseId'),
                shopId: _.get(shop, 'shopId'),
              }),
            ]);

          setCodes((ps) => ({
            ...ps,
            insurancePriceTables,
            insurances,
            checkPriceTable,
          }));

          if (
            isRecheck === YN.NO ||
            (isRecheck === YN.YES && performanceCheckPhotos.length > 0)
          ) {
            const loadedPhotos = performanceCheckPhotos.map((item) => {
              item.state = FileState.Loaded;
              return item;
            });
            setFileItems(loadedPhotos);
          }

          const updateVehicleInformation = {
            ...vehicleInformation,
            detailedModelType:
              _.get(vehicleInformation, 'detailedModelType') ||
              setDetailedModelType({
                madeAt: _.get(vehicleInformation, 'madeAt'),
                assortType: _.get(vehicleInformation, 'VHCTY_ASORT_CODE'),
                vehicleInformation: vehicleInformation,
                displace: _.get(vehicleInformation, 'displacement'),
                maxPerson: _.get(vehicleInformation, 'maxPerson'),
              }),
            VHRNO: _.get(vehicleInformation, 'VHRNO') || VHRNO,
            LOSS_STATUS: _.get(vehicleInformation, 'LOSS_STATUS') || YN.NO,
            MTRS_FOM:
              _.get(vehicleInformation, 'MTRS_FOM') ||
              _.get(vehicleInformation, 'MTRS_FOM_NM'),
          };

          if (
            _.get(checkPriceTable, 'checkPriceItems').length > 0 &&
            insurancePriceTables.length > 0
          ) {
            getCurrentCheckPrice({
              checkPriceTable,
              paymentInformation,
            });
            if (_.get(paymentInformation, 'ASSRNC_TY_SE_CODE') !== '1') {
              getCurrentInsurancePrice({
                insurancePriceTables,
                vehicleInformation: updateVehicleInformation,
                paymentInformation,
              });
            }
          }

          reset({
            ...defaultValues,
            ...checkData,
            licensePlateNumber,
            association,
            enterprise,
            shop,
            inspector,
            customerCompany,
            customer,
            vehicleInformation: updateVehicleInformation,
            vehicleMaintenance,
            paymentInformation: {
              ...paymentInformation,
              checkPriceItemId: String(
                _.get(paymentInformation, 'checkPriceItemId')
              ),
            },
            fileData,
            ...updateVehicleInformation,
            LATEST_TRVL_DSTNC: getDistance(vehicleInformation['TRVL_DSTNC']),
            TRVL_DSTNC: getDistance(vehicleInformation['TRVL_DSTNC']),
            TRVL_DSTNC_STTUS_CODE: getDistanceCode(
              getDistance(vehicleInformation['TRVL_DSTNC']),
              _.get(vehicleInformation, 'PRYE')
            ),
            note: performanceCheckData.note ?? '',
          });

          if (_.get(vehicleInformation, 'TRVL_DSTNC_STTUS_CODE') === null) {
            const [distance, madeAt, detailModel] = await handleTempSaveByApp({
              currentVehicleInformation: {
                ...vehicleInformation,
                VHRNO,
              },
              currentPaymentInformation: paymentInformation,
            });
            (async () => {
              const [checkPriceVAT, checkPrice] = setCheckPrice({
                checkPriceTable,
              });
              const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
                distance,
                madeAt,
                detailModel,
                insurancePriceTables,
              });
              const [totalPrice] = calculatePrices({
                checkPriceVAT,
                checkPrice,
                insurancePriceVAT,
                insurancePrice,
              });
            })();
            setLoading(false);
            return;
          }

          // 튜닝 여부, 용도 변경 여부 양식에 맞게 업데이트 해야함
          // UNLAW_STMD_IEM_SE_CODE, PRPOS_CHANGE_SE_CODE 가 0이면 여부 0으로 세팅
          // 1, 2이면 1로 세팅

          if (checkData === null) {
            handlePartDefaultValues({
              USE_FUEL_CODE: _.get(vehicleInformation, 'USE_FUEL_CODE'),
              GRBX_KND_CODE: _.get(vehicleInformation, 'GRBX_KND_CODE'),
            });
          } else {
            // checkdata 가 있는데 vehicle info 에 STMD_AT 이나 PRPOS_CHANGE_SE_AT 이 없는 경우
            // checkData에 있는 상세값을 보고 세팅
            if (
              _.get(updateVehicleInformation, 'STMD_AT') !== YN.YES &&
              _.get(updateVehicleInformation, 'STMD_AT') !== YN.NO
            ) {
              setValue(
                'STMD_AT',
                _.get(checkData, 'UNLAW_STMD_ITEM_SE_CODE') === '0'
                  ? YN.NO
                  : YN.YES
              );
              setValue(
                'vehicleInformation.STMD_AT',
                _.get(checkData, 'UNLAW_STMD_ITEM_SE_CODE') === '0'
                  ? YN.NO
                  : YN.YES
              );
            }

            if (
              _.get(updateVehicleInformation, 'PRPOS_CHANGE_SE_AT') !==
                YN.YES &&
              _.get(updateVehicleInformation, 'PRPOS_CHANGE_SE_AT') !== YN.NO
            ) {
              setValue(
                'PRPOS_CHANGE_SE_AT',
                _.get(checkData, 'PRPOS_CHANGE_SE_CODE') === '0'
                  ? YN.NO
                  : YN.YES
              );
              setValue(
                'vehicleInformation.PRPOS_CHANGE_SE_AT',
                _.get(checkData, 'PRPOS_CHANGE_SE_CODE') === '0'
                  ? YN.NO
                  : YN.YES
              );
            }
          }

          const finItemId = checkPriceTable.checkPriceItems?.find(
            (el) => el.id === paymentInformation.checkPriceItemId
          );
          const CheckPrice =
            Number(paymentInformation.checkPrice) +
            Number(paymentInformation.checkPriceVAT);
          if (!finItemId) {
            const findTable = checkPriceTable.checkPriceItems.find(
              (el) => el.price === CheckPrice
            );

            if (findTable) {
              const id = findTable.id;
              setValue('paymentInformation.checkPriceItemId', id);
            }
          }
          handleSubmit((formData) => formData)();
        } catch (error) {
          console.log(error);
          const timeout = setTimeout(() => {
            window.cerp.dialog.error(
              '잘못된 접근입니다.',
              '성능점검 기본정보가 없습니다. 처음부터 다시 입력해 주세요.'
            );
            // setTimeout(() => window.close(), 500);
          }, 10);
          return () => clearTimeout(timeout);
        }
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataId]);

  useEffect(() => {
    if (!!currentValues.vehicleInformation.displacement) {
      const { madeAt, VHCTY_ASORT_CODE, detailedModelType, TRVL_DSTNC } =
        currentValues.vehicleInformation;

      const carTypeCode = getCarTypeCode({
        madeAt,
        VHCTY_ASORT_CODE,
        detailedModelType,
      });
      const suffix =
        performanceCheckUtil.Distance.getInsuranceSuffix(TRVL_DSTNC);

      setValue(
        'paymentInformation.insurancePriceCode',
        `${carTypeCode}_${suffix}`
      );
    }
  }, [currentValues.vehicleInformation.displacement]);

  const useWatchedValues = (currentValues) => {
    return useMemo(() => {
      const watchedValues = WATCHED_PROPS.reduce((acc, prop) => {
        acc[prop] = currentValues[prop];
        return acc;
      }, {});
      return JSON.stringify(watchedValues);
    }, [currentValues]);
  };

  useEffect(() => {
    checkAbnormalPart({ currentValues });
    checkFrameStatus(previousValues, currentValues);
  }, [useWatchedValues(currentValues)]);

  useEffect(() => {
    const unlawStmdAt = _.get(currentValues, 'UNLAW_STMD_AT');
    if (unlawStmdAt === '0') setValue('UNLAW_STMD_IEM_SE_CODE', '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_.get(currentValues, 'UNLAW_STMD_AT'), setValue]);

  useEffect(() => {
    if (_.get(currentValues, 'CHK_CRMN_MESURE_VALUE') === YN.NO) {
      setValue('CRMN_MESURE_VALUE', '0.00');
    }

    if (_.get(currentValues, 'CHK_HYDR_MESURE_VALUE') === YN.NO) {
      setValue('HYDR_MESURE_VALUE', '0');
    }

    if (_.get(currentValues, 'CHK_SMOKE_MESURE_VALUE') === YN.NO) {
      setValue('SMOKE_MESURE_VALUE', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentValues.CHK_CRMN_MESURE_VALUE,
    currentValues.CHK_HYDR_MESURE_VALUE,
    currentValues.CHK_SMOKE_MESURE_VALUE,
  ]);

  useEffect(() => {
    if (isSafeToReset) {
      reset(defaultValues);
      window.close();
    }

    return () => setIsSafeToReset(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSafeToReset]);

  useEffect(() => {
    window.addEventListener('beforeunload', async (event) => {
      await window.opener.getListByChildren();
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 현재 유저가 inspector면 유저 정보를 inspector에 업데이트한다.
    (async () => {
      const myInfo = myInfoLoadable.contents;

      if (
        myInfo &&
        myInfo.roleCode === _.find(UserRoleType, { value: 'S_INS' }).value
      ) {
        const [{ association, enterprise, shop }, inspector] =
          separateUserData(myInfo);

        const [
          [checkPriceTable],
          [insurancePriceTables, insuranceCompany, insurances],
        ] = await Promise.all([
          getCheckPriceTable({
            associationId: _.get(association, 'associationId'),
            enterpriseId: _.get(enterprise, 'enterpriseId'),
            shopId: _.get(shop, 'shopId'),
          }),
          getInsurancePriceTables({
            associationId: _.get(association, 'associationId'),
            enterpriseId: _.get(enterprise, 'enterpriseId'),
            shopId: _.get(shop, 'shopId'),
          }),
        ]);

        setCodes((ps) => ({
          ...ps,
          insurancePriceTables,
          insurances,
          checkPriceTable,
        }));

        setValue('association', association);
        setValue('enterprise', enterprise);
        setValue('shop', shop);
        setValue('inspector', {
          ...inspector,
          roleCode: _.find(UserRoleType, {
            label: inspector.userRoleTitle,
          }).value,
        });

        setValue('paymentInformation.ICNY_CODE', insuranceCompany);

        setInsurancePrice({ insurancePriceTables });
        setCheckPrice({ checkPriceTable });
        calculatePrices();
      }
    })();
  }, [myInfoLoadable.contents]);

  const [resetLoading, setResetLoading] = useState(false);
  const refreshGetCarInfo = async () => {
    setResetLoading(true);
    try {
      const data = await performanceReset.deleteInfo(
        currentValues.licensePlateNumber
      );

      if (data) {
        window.cerp.toast.success(
          data.repMessage,
          '차량정보를 다시 조회합니다.'
        );
        const res = await getCarInformation();
        if (res.length) {
          setIsRefresh(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResetLoading(false);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat('ko-KR').format(number);
  };

  // 차량조회 시 국산/수입 여부에 따라 '점검료구분'값 세팅
  const setCheckPriceAuto = (madeAt) => {
    if (!!!codes.checkPriceTable) return;
    const item =
      madeAt === 'F'
        ? _.find(codes.checkPriceTable.checkPriceItems, { priceType: 'F' })
        : _.find(codes.checkPriceTable.checkPriceItems, { priceType: 'D' });
    setValue('paymentInformation.checkPriceItemId', item.id);
  };

  const getCarInfo = async (e) => {
    e.preventDefault();
    setImageString('');
    setEncodingImageSrc('');
    setHistoryResultMsg('');
    getCarHistoryDatas();
    const [distance, madeAt, detailModel] = await getCarInformation();
    madeAt && setCheckPriceAuto(madeAt);
    const [checkPriceVAT, checkPrice] = setCheckPrice();
    const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
      distance,
      madeAt,
      detailModel,
    });

    if (performanceCheckUtil.Distance.getInsuranceSuffix(distance) === 'X') {
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
    }

    calculatePrices({
      checkPriceVAT,
      checkPrice,
      insurancePriceVAT,
      insurancePrice,
    });
    // }
  };

  const handletrvlDistance = (value) => {
    if (performanceCheckUtil.Distance.getInsuranceSuffix(value) === 'X') {
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '1');
      setValue('ASSRNC_TY_SE_CODE', '1');
    } else if (
      _.get(currentValues, 'paymentInformation.ASSRNC_TY_SE_CODE') !== '1' &&
      performanceCheckUtil.Distance.getInsuranceSuffix(value) === 'X'
    ) {
      setValue('paymentInformation.ASSRNC_TY_SE_CODE', '2');
      setValue('ASSRNC_TY_SE_CODE', '2');
    }
    setValue(
      'TRVL_DSTNC_STTUS_CODE',
      getDistanceCode(value, _.get(currentValues, 'vehicleInformation.PRYE'))
    );
    const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
      distance: value,
    });
    calculatePrices({
      insurancePriceVAT,
      insurancePrice,
    });
  };

  const handleDetailedModelType = (type, value) => {
    if (type === 'madeAt' || type === 'assortType') {
      const detailModel = setDetailedModelType({
        type: value,
      });
      const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
        type: value,
        detailModel,
      });
      calculatePrices({
        checkPriceVAT,
        checkPrice,
        insurancePriceVAT,
        insurancePrice,
      });
    } else if (type === 'displace' || type === 'maxPerson') {
      const detailModel = setDetailedModelType({
        type: value,
      });
      const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
        detailModel,
      });
      calculatePrices({
        insurancePriceVAT,
        insurancePrice,
      });
    } else if (type === 'detailModel') {
      const [checkPriceVAT, checkPrice] = setCheckPrice();
      const [insurancePriceVAT, insurancePrice] = setInsurancePrice({
        detailModel: value,
      });
      calculatePrices({
        checkPriceVAT,
        checkPrice,
        insurancePriceVAT,
        insurancePrice,
      });
    }
  };

  useEffect(() => {
    if (manualInput && searchFailed) {
      const newVehicleInformation = {
        CNM: '',
        VIN: '',
        PRYE: 0,
        madeAt: '',
        STMD_AT: 'N',
        cnmDetail: '',
        maxPerson: 0,
        vinRemain: '',
        VHRNO: '',
        TRVL_DSTNC: 0,
        LATEST_TRVL_DSTNC: 0,
        TRVL_DSTNC_STTUS_CODE: '1',
        MTRS_FOM: '',
        MTRS_FOM_NM: '', // 동일하게 입력
        USE_FUEL_NM: '',
        displacement: 0,
        GRBX_KND_NM: baseValueItems['GRBX_KND_CODE'][0].label,
        GRBX_KND_CODE: baseValueItems['GRBX_KND_CODE'][0].value,
        USE_FUEL_CODE: 'a', // 국토부 연료구분 이름 -> 선택 값
        FRST_REGIST_DE: '',
        INSPT_VALID_PD: '', // 새로 만들어주는 값
        VHCTY_ASORT_CODE: '',
        detailedModelType: '',
        PRPOS_CHANGE_SE_AT: 'N',
        INSPT_VALID_PD_BGNDE: '',
        INSPT_VALID_PD_ENDDE: '',
        LOSS_STATUS: 'N',
      };
      setValue('vehicleInformation', newVehicleInformation);
    }
  }, [manualInput, searchFailed]);

  // 수기입력 체크시에 '주요장치 상태 및 누유, 누수 표시' 현재 연료에 해당하는 기본값으로 설정
  useEffect(() => {
    if (manualInput) {
      handlePartDefaultValues({
        USE_FUEL_CODE: currentValues.USE_FUEL_CODE,
      });
    }
  }, [manualInput]);

  const headerTemplate = (
    <div className="flex flex-row">
      <span className="flex-1">기본 정보</span>
      <div className="flex flex-column text-right lg:flex-row">
        <div className="mr-1">
          <span className="mr-2">점검료+보험료 총액</span>
          <span className="text-primary">
            {formatNumber(
              currentValues.paymentInformation?.insurancePrice +
                currentValues.paymentInformation?.insurancePriceVAT +
                currentValues.paymentInformation?.checkPrice +
                currentValues.paymentInformation?.checkPriceVAT
            )}{' '}
            원
          </span>
        </div>
        {statementNumber && (
          <div className="mt-2 lg:mt-0 ml-3 mr-1">
            <span className="mr-2">성능지번호</span>
            <span className="text-primary">{statementNumber}</span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <BlockUI
      fullScreen
      template={
        loading && (
          <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
        )
      }
      blocked={loading}
    >
      <form className="p-3 h-screen" autoComplete="off" id="pcr">
        <div className="grid">
          <div className="col-12 lg:col-6">
            <div className="grid">
              <div className="col-12">
                <Panel header={headerTemplate} className="shadow-2">
                  <div className="grid">
                    <div className="md:col-6 col-12">
                      <PopupInputText
                        control={control}
                        id="inspector_popup"
                        onClickInputs={() => {
                          setIsDialogOpened((ps) => ({
                            ...ps,
                            userSearch: true,
                          }));
                        }}
                        item={{
                          code: 'inspector.userName',
                          label: '성능점검자',
                          required: true,
                        }}
                        classNameProps={true}
                        placeholder="점검자 선택"
                      />
                      {getFormErrorMessage('inspector.userName')}
                    </div>
                    <div className="md:col-6 col-12">
                      <PopupInputText
                        control={control}
                        id="customer_popup"
                        onClickInputs={() =>
                          setIsDialogOpened((ps) => ({
                            ...ps,
                            customerSearch: true,
                          }))
                        }
                        classNameProps={true}
                        placeholder="고객 선택"
                        item={{
                          code: 'customer.customerName',
                          label: '고객',
                          required: true,
                        }}
                      />
                      {getFormErrorMessage('customer.customerName')}
                    </div>
                    <div className="col-12">
                      <Controller
                        control={control}
                        name="licensePlateNumber"
                        defaultValue=""
                        rules={{
                          required: '필수 입력 항목입니다.',
                        }}
                        render={({ field, fieldState, formState }) => (
                          <div className="flex flex-column">
                            <div className="p-inputgroup">
                              <Grouped.TitledInputText
                                id={field.name}
                                boldStyle={true}
                                placeholder="차량번호를 입력하세요."
                                title="차량번호"
                                {...field}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  value = value.replace(
                                    /[^ㄱ-힣0-9ㆍᆢㆍ‥ㆍ ᆢㆍ ᆢ]+/g,
                                    ''
                                  );
                                  field.onChange(value);
                                  setValue('VHRNO', value);
                                  setValue('vehicleInformation.VHRNO', value);
                                }}
                                onFocus={(e) => e.target.select()}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    getCarInfo(e);
                                  }
                                }}
                              />
                              <div
                                className="flex flex-row align-items-center justify-content-between"
                                style={{ minWidth: 'fit-content' }}
                              >
                                <>
                                  <Button.Default
                                    type="button"
                                    label="차량정보조회"
                                    icon="pi pi-search"
                                    className={classNames('border-noround')}
                                    loading={carInformationLoading}
                                    onClick={(e) => {
                                      getCarInfo(e);
                                    }}
                                  />
                                </>
                              </div>
                            </div>
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <Checkbox
                        // disabled={!noCarInfo}
                        inputId="manualInput"
                        checked={manualInput}
                        onChange={() => setManualInput(!manualInput)}
                      />
                      <label className={`ml-2`} htmlFor="manualInput">
                        <span className="">차량정보 수기입력</span>
                        <span
                          className={`ml-1 ${
                            manualInput && 'text-red-500 font-bold'
                          }`}
                        >
                          (모두 직접 입력하셔야 합니다.)
                        </span>
                      </label>
                    </div>
                  </div>
                </Panel>
              </div>

              <div className="col-12">
                <Panel header="점검료" className="shadow-2">
                  <div className="grid">
                    <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2 ">
                      <Controller
                        control={control}
                        name={'paymentInformation.checkPriceItemId'}
                        defaultValue=""
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field, fieldState, formState }) => (
                          <>
                            <Grouped.TitledDropdown
                              id={field.name}
                              {...field}
                              title={'점검료구분'}
                              options={_.get(
                                codes,
                                'checkPriceTable.checkPriceItems'
                              )}
                              disabled={
                                curMolitState !== 'W' && !!curMolitState
                              }
                              classNameProps={true}
                              className="border-primary"
                              placeholder="없음"
                              optionLabel="title"
                              optionValue="id"
                              onChange={({ value }) => {
                                field.onChange(value);

                                const [checkPriceVAT, checkPrice] =
                                  setCheckPrice({ selectedItemId: value });
                                calculatePrices({
                                  checkPriceVAT,
                                  checkPrice,
                                });
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2 ">
                      <Controller
                        control={control}
                        name={'paymentInformation.totalCheckPrice'}
                        defaultValue={0}
                        // rules={{
                        //   required: formRequired && '필수 입력항목입니다.',
                        // }}
                        render={({ field }) => (
                          <Grouped.TitledInputNumber
                            id={field.name}
                            title={'점검료 (VAT포함)'}
                            {...field}
                            readOnly
                            className="font-bold bg-gray-100"
                            suffix=" 원"
                            value={
                              currentValues.paymentInformation?.checkPrice +
                              currentValues.paymentInformation?.checkPriceVAT
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name={'paymentInformation.checkPricePaidBy'}
                        defaultValue=""
                        render={({ field }) => (
                          <Grouped.TitledDropdown
                            id={field.name}
                            title={'결제'}
                            {...field}
                            options={PAYMENT_METHOD}
                            onChange={({ value }) => {
                              field.onChange(value);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name={'paymentInformation.discountedCheckPrice'}
                        defaultValue={''}
                        render={({ field }) => (
                          <Grouped.TitledInputNumber
                            readOnly={!!curTransferState}
                            id={field.name}
                            title="할인 금액"
                            {...field}
                            onFocus={(e) => e.target.select()}
                            suffix=" 원"
                            onChange={(e) => {
                              field.onChange(e);

                              setCheckPrice({
                                discountedCheckPrice: e,
                              });
                              calculatePrices({
                                discountedCheckPrice: e,
                              });
                            }}
                            onBlur={(e) => {
                              const value = Number(
                                e.target.value.replace(/[^0-9]/g, '')
                              );
                              field.onChange(value);

                              setCheckPrice({
                                discountedCheckPrice: value,
                              });
                              calculatePrices({
                                discountedCheckPrice: value,
                              });
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-8 lg:col-8 mb-2">
                      <Controller
                        control={control}
                        name={'paymentInformation.discountReason'}
                        defaultValue=""
                        rules={{
                          required:
                            _.get(
                              currentValues,
                              'paymentInformation.discountedCheckPrice'
                            ) > 0 && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputText
                              className="border-primary"
                              disabled={!!curTransferState}
                              id={field.name}
                              title="할인사유"
                              placeholder="할인 사유를 입력하세요."
                              {...field}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Panel>
              </div>

              <div className="col-12 h-full">
                <Panel
                  header={() => (
                    <div className="flex justify-content-between">
                      <div>자동차의 기본 상태</div>

                      <div
                        className="cursor-pointer flex align-items-center target-icon"
                        onClick={() => {
                          setIsRefresh(true);
                          refreshGetCarInfo();
                        }}
                        data-pr-tooltip="현재 자동차의 기본상태를 업데이트 합니다."
                      >
                        <i
                          className={`${
                            resetLoading && 'pi-spin'
                          } pi pi-refresh mr-1`}
                        />
                        <span className="font-normal text-sm">업데이트</span>
                      </div>
                      <Tooltip target=".target-icon" />
                    </div>
                  )}
                  className="shadow-2"
                >
                  <div className="grid">
                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="VIN"
                        defaultValue=""
                        rules={{
                          required: '차량 정보를 조회해주세요.',
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledInputText
                              boldStyle={true}
                              id={field.name}
                              title={baseTitles[field.name]}
                              {...field}
                              readOnly={!manualInput}
                              onChange={(e) => {
                                const value = CommonUtil.Formatter.vin(
                                  e.target.value
                                );
                                field.onChange(value);
                                setValue('vehicleInformation.VIN', value);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 md:pl-0 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="vehicleInformation.vinRemain"
                        defaultValue=""
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <InputText
                              className="border-primary border-1 font-bold"
                              id={field.name}
                              {...field}
                              style={{ width: '100%', height: '100%' }}
                              placeholder="차대번호 뒷 6자리(영문,숫자)"
                              onChange={(e) => {
                                const value = CommonUtil.Formatter.vinRemain(
                                  e.target.value
                                );
                                field.onChange(value);
                                setValue('vehicleInformation.vinRemain', value);
                              }}
                              onFocus={(e) => e.target.select()}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="PRYE"
                        defaultValue=""
                        rules={{
                          required: '차량 정보를 조회해주세요.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputText
                              id={field.name}
                              title={baseTitles[field.name]}
                              {...field}
                              readOnly={!manualInput}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setValue(
                                  'vehicleInformation.PRYE',
                                  Number(e.target.value)
                                );
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="CNM"
                        defaultValue=""
                        rules={{
                          required: '차량 정보를 조회해주세요.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputText
                              boldStyle={true}
                              id={field.name}
                              title={baseTitles[field.name]}
                              {...field}
                              readOnly={!manualInput}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setValue(
                                  'vehicleInformation.CNM',
                                  e.target.value
                                );
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 mb-2">
                      <div className="flex">
                        <span
                          className="h-full p-inputgroup-addon w-full font-medium text-center min-w-3 w-4 max-w-5"
                          style={{
                            display: 'table',
                          }}
                        >
                          <span
                            style={{
                              display: 'table-cell',
                              verticalAlign: 'middle',
                              wordBreak: 'keep-all',
                            }}
                          >
                            최초등록일
                          </span>
                        </span>
                        <div className="p-inputgroup">
                          <Controller
                            control={control}
                            name="FRST_REGIST_DE"
                            defaultValue=""
                            rules={{
                              required: '차량 정보를 조회해 주세요.',
                            }}
                            render={({ field }) => (
                              <InputMask
                                {...field}
                                mask="9999-99-99"
                                slotChar="yyyy-mm-dd"
                                readOnly={!manualInput}
                                className={!manualInput ? 'bg-gray-100' : ''}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  let value = e.target.value.replaceAll(
                                    '-',
                                    ''
                                  );
                                  setValue(
                                    'vehicleInformation.FRST_REGIST_DE',
                                    value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 sm:col-12 md:col-6 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="MTRS_FOM"
                        defaultValue=""
                        rules={{
                          required: '차량 정보를 조회해주세요.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputText
                              id={field.name}
                              title={baseTitles[field.name]}
                              {...field}
                              readOnly={!manualInput}
                              onChange={(e) => {
                                const value = CommonUtil.Formatter.vin(
                                  e.target.value
                                );
                                field.onChange(value);
                                setValue('vehicleInformation.MTRS_FOM', value);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 md:col-12 lg:col-6 mb-2">
                      <div className="flex">
                        <span
                          className="h-full p-inputgroup-addon w-full font-medium text-center min-w-3 w-4 max-w-5"
                          style={{
                            display: 'table',
                          }}
                        >
                          <span
                            style={{
                              display: 'table-cell',
                              verticalAlign: 'middle',
                              wordBreak: 'keep-all',
                            }}
                          >
                            검사유효기간
                          </span>
                        </span>
                        <div className="p-inputgroup">
                          <Controller
                            control={control}
                            name="INSPT_VALID_PD_BGNDE"
                            defaultValue=""
                            rules={{
                              required: '차량 정보를 조회해 주세요.',
                            }}
                            render={({ field }) => (
                              <InputMask
                                {...field}
                                mask="9999-99-99"
                                slotChar="yyyy-mm-dd"
                                readOnly={!manualInput}
                                className={!manualInput ? 'bg-gray-100' : ''}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  let value = e.target.value.replaceAll(
                                    '-',
                                    ''
                                  );
                                  setValue(
                                    'vehicleInformation.INSPT_VALID_PD_BGNDE',
                                    value
                                  );
                                }}
                              />
                            )}
                          />
                          <span className="p-inputgroup-addon w-min"> ~ </span>
                          <Controller
                            control={control}
                            name="INSPT_VALID_PD_ENDDE"
                            defaultValue=""
                            rules={{
                              required: '차량 정보를 조회해 주세요.',
                            }}
                            render={({ field }) => (
                              <InputMask
                                {...field}
                                mask="9999-99-99"
                                slotChar="yyyy-mm-dd"
                                readOnly={!manualInput}
                                className={!manualInput ? 'bg-gray-100' : ''}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                  let value = e.target.value.replaceAll(
                                    '-',
                                    ''
                                  );
                                  setValue(
                                    'vehicleInformation.INSPT_VALID_PD_ENDDE',
                                    value
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 sm:col-12 lg:col-6 mb-2">
                      <Controller
                        control={control}
                        name="SAMENSS_CNFIRM_CODE"
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title="차대번호 표기"
                              id={field.name}
                              {...field}
                              items={baseValueItems[field.name]}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-6 mb-2">
                      <Controller
                        control={control}
                        name="GAUGE_FNCT_AT"
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              items={baseValueItems[field.name]}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-3 mb-2">
                      <Controller
                        control={control}
                        name="LATEST_TRVL_DSTNC"
                        defaultValue="0"
                        render={({ field }) => (
                          <div className="relative h-full">
                            <p
                              className="absolute"
                              style={{
                                right: '0',
                                marginRight: '5px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                userSelect: 'none',
                              }}
                            >
                              Km
                            </p>
                            <Grouped.TitledInputNumber
                              id={field.name}
                              className="bg-gray-100 pr-5"
                              title={baseTitles[field.name]}
                              {...field}
                              readOnly
                            />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-3 mb-2 md:pl-0 lg:pl-0">
                      <Controller
                        control={control}
                        name="TRVL_DSTNC"
                        defaultValue={0}
                        rules={{
                          validate: {
                            required: (val) => {
                              if ((!val || val <= 0) && formRequired) {
                                return '필수 입력항목입니다.';
                              }
                            },
                          },
                        }}
                        render={({ field }) => (
                          <div className="relative h-full">
                            <p
                              className="absolute font-bold"
                              style={{
                                right: '0',
                                marginRight: '4px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                userSelect: 'none',
                              }}
                            >
                              Km
                            </p>
                            <Grouped.TitledInputNumber
                              id={field.name}
                              classNameProps={true}
                              className="border-primary border-1 pr-5 text-lg font-bold"
                              title={baseTitles[field.name]}
                              {...field}
                              onChange={(e) => {
                                const value =
                                  CommonUtil.Formatter.trvlDistance(e);
                                field.onChange(value);
                                handletrvlDistance(value);
                                if (manualInput) {
                                  setValue(
                                    'vehicleInformation.TRVL_DSTNC',
                                    value
                                  );
                                  setValue(
                                    'vehicleInformation.LATEST_TRVL_DSTNC',
                                    value
                                  );
                                  setValue(
                                    'vehicleInformation.TRVL_DSTNC_STTUS_CODE',
                                    getDistanceCode(
                                      value,
                                      _.get(
                                        currentValues,
                                        'vehicleInformation.PRYE'
                                      )
                                    )
                                  );
                                }
                                if (
                                  performanceCheckUtil.Distance.getInsuranceSuffix(
                                    value
                                  ) === 'X'
                                ) {
                                  setValue(
                                    'paymentInformation.ASSRNC_TY_SE_CODE',
                                    '1'
                                  );
                                  setValue('ASSRNC_TY_SE_CODE', '1');
                                } else if (
                                  _.get(
                                    currentValues,
                                    'paymentInformation.ASSRNC_TY_SE_CODE'
                                  ) !== '1' &&
                                  performanceCheckUtil.Distance.getInsuranceSuffix(
                                    value
                                  ) === 'X'
                                ) {
                                  setValue(
                                    'paymentInformation.ASSRNC_TY_SE_CODE',
                                    '2'
                                  );
                                  setValue('ASSRNC_TY_SE_CODE', '2');
                                }
                                setValue(
                                  'TRVL_DSTNC_STTUS_CODE',
                                  getDistanceCode(
                                    value,
                                    _.get(
                                      currentValues,
                                      'vehicleInformation.PRYE'
                                    )
                                  )
                                );
                                const [insurancePriceVAT, insurancePrice] =
                                  setInsurancePrice({
                                    distance: value,
                                  });
                                calculatePrices({
                                  insurancePriceVAT,
                                  insurancePrice,
                                });
                              }}
                              onBlur={(e) => {
                                const value = CommonUtil.Formatter.trvlDistance(
                                  e.target.value
                                );
                                field.onChange(value);
                                handletrvlDistance(value);
                              }}
                              onFocus={(e) => e.target.select()}
                              min={0}
                              placeholder={0}
                              max={9999999}
                              maxLength={7}
                              allowEmpty={true}
                            />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-6 mb-2">
                      <Controller
                        control={control}
                        name="TRVL_DSTNC_STTUS_CODE"
                        rules={{
                          required: formRequired && '주행거리를 입력하세요.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              // readOnly
                              disabled={!manualInput}
                              items={baseValueItems[field.name]}
                              onChange={(e) => {
                                setValue(
                                  'vehicleInformation.TRVL_DSTNC_STTUS_CODE',
                                  e
                                );
                                field.onChange(e);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-6 mb-2">
                      <Controller
                        control={control}
                        name="vehicleInformation.madeAt"
                        defaultValue="D"
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledDropdown
                              id={field.name}
                              title={'차종구분'}
                              {...field}
                              options={VEHICLE_TYPE_LEVEL1}
                              onChange={({ value }) => {
                                field.onChange(value);
                                handleDetailedModelType('madeAt', value);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-6 mb-2 md:pl-0 lg:pl-0">
                      <Controller
                        control={control}
                        name="vehicleInformation.VHCTY_ASORT_CODE"
                        defaultValue="1"
                        rules={{
                          required: formRequired && '필수 입력항목입니다.',
                        }}
                        render={({ field }) => (
                          <>
                            <Dropdown
                              className="flex w-full h-full border-primary border-1"
                              id={field.name}
                              {...field}
                              options={baseValueItems['VHCTY_ASORT_CODE']}
                              onChange={({ value }) => {
                                field.onChange(value);
                                setValue('VHCTY_ASORT_CODE', value);
                                const detailModel = setDetailedModelType({
                                  assortType: value,
                                });
                                const [checkPriceVAT, checkPrice] =
                                  setCheckPrice();
                                const [insurancePriceVAT, insurancePrice] =
                                  setInsurancePrice({
                                    assortType: value,
                                    detailModel,
                                  });
                                calculatePrices({
                                  checkPriceVAT,
                                  checkPrice,
                                  insurancePriceVAT,
                                  insurancePrice,
                                });
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="USE_FUEL_CODE"
                        defaultValue={!manualInput ? 'a' : ''}
                        rules={{
                          required: formRequired && '차량 정보를 조회해주세요.',
                        }}
                        render={({ field }) => (
                          <Grouped.TitledDropdown
                            id={field.name}
                            {...field}
                            title="연료구분"
                            options={baseValueItems['USE_FUEL_CODE']}
                            onChange={({ value }) => {
                              field.onChange(value);
                              setValue(
                                'vehicleInformation.USE_FUEL_CODE',
                                value
                              );
                              const updateVehicleInfo = {
                                ..._.get(currentValues, 'vehicleInformation'),
                                USE_FUEL_CODE: value,
                              };
                              handleGasMesureValues(updateVehicleInfo);
                              setDetailedModelType({
                                vehicleInformation: updateVehicleInfo,
                              });
                              handlePartDefaultValues({
                                USE_FUEL_CODE: value,
                              });
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="vehicleInformation.displacement"
                        defaultValue={0}
                        rules={{
                          validate: (v) => {
                            if (
                              formRequired &&
                              v <= 0 &&
                              !(
                                performanceCheckUtil.Checker.Fuel.isElectronic(
                                  _.get(currentValues, 'vehicleInformation')
                                ) ||
                                performanceCheckUtil.Checker.Fuel.isHydrogen(
                                  _.get(currentValues, 'vehicleInformation')
                                ) ||
                                _.get(currentValues, 'MTRS_FOM') === '-'
                              )
                            ) {
                              return '필수 입력항목입니다.';
                            }
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputNumber
                              id={field.name}
                              {...field}
                              title="배기량"
                              suffix="cc"
                              selectOnFocus
                              classNameProps={true}
                              className="border-primary text-lg font-bold"
                              min={0}
                              max={200000}
                              onChange={(e) => {
                                const max = 200000;
                                let value = e;
                                if (value > max) {
                                  value = max;
                                }
                                field.onChange(value);
                                handleDetailedModelType('displace', value);
                              }}
                              onBlur={(e) => {
                                const max = 200000;
                                let value = Number(
                                  e.target.value.replace(/[^0-9]/g, '')
                                );
                                if (value > max) {
                                  value = max;
                                }
                                field.onChange(value);
                                handleDetailedModelType('displace', value);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-6 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="vehicleInformation.maxPerson"
                        defaultValue={0}
                        rules={{
                          required: '필수 입력항목입니다.',
                          validate: (v) => {
                            if (
                              !(_.get(currentValues, 'MTRS_FOM') === '-') &&
                              v <= 0 &&
                              formRequired
                            ) {
                              return '승차인원은 1명 이상입니다.';
                            }
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <Grouped.TitledInputNumber
                              id={field.name}
                              {...field}
                              title="승차 인원"
                              suffix="명 이하"
                              selectOnFocus
                              classNameProps={true}
                              className="border-primary text-lg font-bold"
                              error={_.get(errors, field.name)}
                              min={0}
                              max={99}
                              onChange={(e) => {
                                field.onChange(e);
                                handleDetailedModelType('maxPerson', e);
                              }}
                              onBlur={(e) => {
                                const max = 99;
                                let value = Number(
                                  e.target.value.replace(/[^0-9]/g, '')
                                );

                                if (value > max) {
                                  value = max;
                                }

                                field.onChange(value);
                                handleDetailedModelType('maxPerson', value);
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <div className="p-inputgroup h-full">
                        <span
                          className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                          style={{ wordBreak: 'keep-all' }}
                        >
                          배출가스
                        </span>
                        <div className="p-inputgroup-addon bg-transparent border-primary w-full">
                          <div className="grid -m-1 w-full">
                            <div className="col-12 sm:col-6 lg:col-4">
                              <div className="flex flex-auto flex-column align-items-start justify-content-start gap-2 h-full">
                                <Controller
                                  control={control}
                                  name="CHK_CRMN_MESURE_VALUE"
                                  defaultValue="Y"
                                  render={({ field, fieldState }) => (
                                    <div className="flex flex-auto flex-grow-0 align-items-center justify-content-start gap-1 h-full">
                                      <Checkbox
                                        inputId={field.name}
                                        checked={field.value}
                                        onChange={(e) =>
                                          field.onChange(e.checked)
                                        }
                                        trueValue="Y"
                                        falseValue="N"
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                      />
                                      <label
                                        htmlFor={field.name}
                                        className="p-checkbox-label"
                                      >
                                        일산화탄소(CO)
                                      </label>
                                    </div>
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="CRMN_MESURE_VALUE"
                                  defaultValue={0.0}
                                  render={({ field, fieldState }) => (
                                    <div className="p-inputgroup w-full h-full">
                                      <InputNumber
                                        id={field.name}
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        onBlur={(e) => {
                                          const max = 100;
                                          const value = Number(e.target.value);
                                          field.onChange(
                                            value > max ? max : value
                                          );
                                        }}
                                        onFocus={(e) => e.target.select()}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        max={100}
                                        disabled={
                                          currentValues[`CHK_${field.name}`] ===
                                          YN.NO
                                        }
                                        error={errors[field.name]}
                                      />
                                      <span className="p-inputgroup-addon">
                                        %
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-12 sm:col-6 lg:col-4">
                              <div className="flex flex-auto flex-column align-items-start justify-content-start gap-2 h-full">
                                <Controller
                                  control={control}
                                  name="CHK_HYDR_MESURE_VALUE"
                                  defaultValue="Y"
                                  render={({ field, fieldState }) => (
                                    <div className="flex flex-auto flex-grow-0 align-items-center justify-content-start gap-1 h-full">
                                      <Checkbox
                                        inputId={field.name}
                                        checked={field.value}
                                        trueValue="Y"
                                        falseValue="N"
                                        onChange={(e) =>
                                          field.onChange(e.checked)
                                        }
                                        onBlur={(e) => {
                                          const max = 100;
                                          const value = Number(e.target.value);
                                          field.onChange(
                                            value > max ? max : value
                                          );
                                        }}
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                      />
                                      <label
                                        htmlFor={field.name}
                                        className="p-checkbox-label"
                                      >
                                        탄화수소(HC)
                                      </label>
                                    </div>
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="HYDR_MESURE_VALUE"
                                  defaultValue={0}
                                  render={({ field, fieldState }) => (
                                    <div className="p-inputgroup w-full h-full">
                                      <InputNumber
                                        id={field.name}
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        onBlur={(e) => {
                                          const max = 100;
                                          const value = Number(e.target.value);
                                          field.onChange(
                                            value > max ? max : value
                                          );
                                        }}
                                        onFocus={(e) => e.target.select()}
                                        max={100}
                                        disabled={
                                          currentValues[`CHK_${field.name}`] ===
                                          YN.NO
                                        }
                                        error={errors[field.name]}
                                      />
                                      <span className="p-inputgroup-addon">
                                        ppm
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-12 sm:col-6 lg:col-4">
                              <div className="flex flex-auto flex-column align-items-start justify-content-start gap-2 h-full">
                                <Controller
                                  control={control}
                                  name="CHK_SMOKE_MESURE_VALUE"
                                  defaultValue="N"
                                  render={({ field, fieldState }) => (
                                    <div className="flex flex-auto flex-grow-0 align-items-center justify-content-start gap-1 h-full">
                                      <Checkbox
                                        inputId={field.name}
                                        checked={field.value}
                                        trueValue="Y"
                                        falseValue="N"
                                        onChange={(e) =>
                                          field.onChange(e.checked)
                                        }
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                      />
                                      <label
                                        htmlFor={field.name}
                                        className="p-checkbox-label"
                                      >
                                        매연
                                      </label>
                                    </div>
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="SMOKE_MESURE_VALUE"
                                  defaultValue={0}
                                  render={({ field, fieldState }) => (
                                    <div className="p-inputgroup w-full h-full">
                                      <InputNumber
                                        id={field.name}
                                        className={classNames({
                                          'p-invalid': fieldState.invalid,
                                        })}
                                        {...field}
                                        onChange={(e) => {
                                          field.onChange(e.value);
                                        }}
                                        onBlur={(e) => {
                                          const max = 100;
                                          const value = Number(e.target.value);
                                          field.onChange(
                                            value > max ? max : value
                                          );
                                        }}
                                        onFocus={(e) => e.target.select()}
                                        // minFractionDigits={1}
                                        max={100}
                                        disabled={
                                          currentValues[`CHK_${field.name}`] ===
                                          YN.NO
                                        }
                                        error={errors[field.name]}
                                      />
                                      <span className="p-inputgroup-addon">
                                        %
                                      </span>
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <Controller
                        control={control}
                        name="GRBX_KND_CODE"
                        render={({ field, fieldState }) => (
                          <Grouped.TitledRadioButton
                            title={baseTitles[field.name]}
                            id={field.name}
                            {...field}
                            error={errors[field.name]}
                            disabled={
                              !!currentValues.vehicleInformation.GRBX_KND_CODE
                            }
                            items={baseValueItems[field.name]}
                            onChange={(e) => {
                              console.log(e);
                              setValue('vehicleInformation.GRBX_KND_CODE', e);
                              setValue(
                                'vehicleInformation.GRBX_KND_NM',
                                baseValueItems[field.name].find(
                                  (el) => el.value === e
                                ).label
                              );
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <Panel
                        header={
                          <div className="flex gap-3 align-items-center justify-content-between flex-wrap">
                            <div>보험료 조회</div>
                            <div className="text-base flex align-items-center gap-1">
                              <i
                                className="pi pi-exclamation-circle"
                                style={{ color: 'var(--red-600)' }}
                              />
                              <span className="text-red-600 font-normal">
                                보험료는{' '}
                                <span className="font-bold">
                                  주행거리, 배기량, 차종
                                </span>
                                에 따라 자동 계산됩니다.
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <div className="grid">
                          {myInfoLoadable.state === 'hasValue' &&
                            checkEwTarget(myInfoLoadable.contents) && (
                              <div className="col-12">
                                <Controller
                                  control={control}
                                  name="paymentInformation.ewYN"
                                  defaultValue={'N'}
                                  render={({ field }) => (
                                    <div className="flex">
                                      <Checkbox
                                        className="mr-2"
                                        // id={field.name}
                                        inputId={field.name}
                                        {...field}
                                        checked={
                                          _.get(
                                            currentValues,
                                            'paymentInformation.ewYN'
                                          ) === 'Y'
                                        }
                                        onChange={({ checked }) => {
                                          field.onChange(checked ? 'Y' : 'N');
                                        }}
                                      />
                                      <label htmlFor={field.name}>
                                        연장 보증 서비스 (EW) 대상 차량
                                      </label>
                                    </div>
                                  )}
                                />
                              </div>
                            )}
                          <div className="col-12">
                            <Controller
                              control={control}
                              name={'vehicleInformation.detailedModelType'}
                              defaultValue="SA"
                              rules={{
                                required:
                                  formRequired && '필수 입력항목입니다.',
                              }}
                              render={({ field }) => (
                                <>
                                  <Grouped.TitledDropdown
                                    id={field.name}
                                    {...field}
                                    title="차종상세구분"
                                    disabled={
                                      currentValues.paymentInformation
                                        .ASSRNC_TY_SE_CODE === '1'
                                    }
                                    className="border-primary border-1"
                                    classNameProps={true}
                                    options={detailedModelTypeOptions}
                                    onChange={({ value }) => {
                                      if (value) field.onChange(value);
                                      handleDetailedModelType(
                                        'detailModel',
                                        value
                                      );
                                    }}
                                  />
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                            <Controller
                              control={control}
                              name={'paymentInformation.ASSRNC_TY_SE_CODE'}
                              defaultValue="2"
                              rules={{
                                required: '필수 입력항목입니다.',
                              }}
                              render={({ field }) => (
                                <div className="bg-transparent flex flex-auto flex-wrap h-full align-items-center justify-content-start gap-3">
                                  {baseValueItems['ASSRNC_TY_SE_CODE'].map(
                                    (item, idx) => {
                                      return (
                                        <div key={`${field.name}_${idx}`}>
                                          <div className="flex flex-auto align-items-center justify-content-start gap-1">
                                            <RadioButton
                                              inputId={`trb_${field.name}_${idx}`}
                                              name={field.name}
                                              value={item.value}
                                              onChange={(e) => {
                                                field.onChange(e);
                                                setValue(
                                                  'ASSRNC_TY_SE_CODE',
                                                  e.value
                                                );

                                                if (e.value === '2') {
                                                  const [
                                                    insurancePriceVAT,
                                                    insurancePrice,
                                                  ] = setInsurancePrice({
                                                    warrantyType: e.value,
                                                  });
                                                  const [totalPrice] =
                                                    calculatePrices({
                                                      checkPriceVAT,
                                                      checkPrice,
                                                      insurancePriceVAT,
                                                      insurancePrice,
                                                    });
                                                  setValue(
                                                    'paymentInformation.insurancePricePaidYN',
                                                    'N'
                                                  );
                                                } else {
                                                  const [
                                                    insurancePriceVAT,
                                                    insurancePrice,
                                                  ] = setInsurancePrice({
                                                    warrantyType: e.value,
                                                  });
                                                  const [totalPrice] =
                                                    calculatePrices({
                                                      insurancePriceVAT,
                                                      insurancePrice,
                                                    });

                                                  setValue(
                                                    'paymentInformation.insurancePricePaidYN',
                                                    'Y'
                                                  );
                                                  setValue(
                                                    'paymentInformation.ASSRNC_TY_SE_CODE',
                                                    '1'
                                                  );
                                                  setValue(
                                                    'ASSRNC_TY_SE_CODE',
                                                    '1'
                                                  );
                                                  //* 보험료 결제시점, 결제여부 업데이트
                                                }
                                              }}
                                              checked={
                                                field.value === item.value
                                              }
                                              disabled={
                                                performanceCheckUtil.Distance.getInsuranceSuffix(
                                                  _.get(
                                                    currentValues,
                                                    'TRVL_DSTNC'
                                                  )
                                                ) === 'X'
                                              }
                                            />
                                            <label
                                              className={item.labelColor}
                                              htmlFor={`trb_${field.name}_${idx}`}
                                            >
                                              {item.label}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            />
                          </div>
                          <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                            <Controller
                              control={control}
                              name="paymentInformation.ICNY_CODE"
                              defaultValue=""
                              rules={{
                                validate: (v) => {
                                  if (
                                    formRequired &&
                                    _.get(
                                      currentValues,
                                      'paymentInformation.ASSRNC_TY_SE_CODE'
                                    ) === '2' &&
                                    v === ''
                                  ) {
                                    return '보증 업체를 선택하세요.';
                                  }
                                },
                              }}
                              render={({ field, fieldState, formState }) => (
                                <>
                                  <Grouped.TitledDropdown
                                    id={field.name}
                                    {...field}
                                    title="보증업체"
                                    options={codes.insurances}
                                    placeholder="없음"
                                    disabled={
                                      performanceCheckUtil.Distance.getInsuranceSuffix(
                                        _.get(currentValues, 'TRVL_DSTNC')
                                      ) === 'X'
                                    }
                                    onChange={({ value }) => {
                                      field.onChange(value);

                                      const [checkPriceVAT, checkPrice] =
                                        setCheckPrice();
                                      const [
                                        insurancePriceVAT,
                                        insurancePrice,
                                      ] = setInsurancePrice({
                                        warrantyType: value,
                                      });
                                      const [totalPrice] = calculatePrices({
                                        checkPriceVAT,
                                        checkPrice,
                                        insurancePriceVAT,
                                        insurancePrice,
                                      });
                                    }}
                                  />
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                            <Controller
                              control={control}
                              name={'paymentInformation.totalInsurancePrice'}
                              defaultValue={0}
                              render={({ field }) => (
                                <Grouped.TitledInputNumber
                                  className="font-bold bg-gray-100"
                                  id={field.name}
                                  title={'보험료 (VAT포함)'}
                                  {...field}
                                  readOnly
                                  suffix=" 원"
                                  value={
                                    currentValues.paymentInformation
                                      ?.insurancePrice +
                                    currentValues.paymentInformation
                                      ?.insurancePriceVAT
                                  }
                                />
                              )}
                            />
                          </div>
                          <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                            <Controller
                              control={control}
                              name={'paymentInformation.insurancePricePaidBy'}
                              defaultValue=""
                              render={({ field }) => (
                                <Grouped.TitledDropdown
                                  id={field.name}
                                  title={'결제'}
                                  {...field}
                                  options={PAYMENT_METHOD}
                                  onChange={({ value }) => {
                                    field.onChange(value);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {isRecheck && (
                            <>
                              <div className="col-12 mb-1">
                                <Controller
                                  control={control}
                                  name="paymentInformation.paidInsurancePriceAmount"
                                  defaultValue={0}
                                  render={({ field }) => (
                                    <Grouped.TitledInputNumber
                                      id={field.name}
                                      title="기존 보험료"
                                      {...field}
                                      readOnly
                                      suffix=" 원"
                                    />
                                  )}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </Panel>
                    </div>
                    <div className="col-12 sm:col-12 lg:col-4">
                      <Button.Default
                        className="w-full h-full"
                        label="리콜 여부 조회 바로가기"
                        icon="pi pi-external-link"
                        iconPos="right"
                        type={'button'}
                        onClick={(e) => {
                          e.preventDefault();
                          CommonUtil.Window.popup(
                            'https://www.car.go.kr/home/main.do',
                            '리콜 여부 조회',
                            500,
                            700
                          );
                        }}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-4">
                      <Controller
                        control={control}
                        name="RECALL_TRGET_AT"
                        rules={{
                          required: '필수 입력항목입니다.',
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-4">
                      <Controller
                        control={control}
                        name="RECALL_FLFL_AT"
                        rules={{
                          required:
                            formRequired &&
                            _.get(currentValues, 'RECALL_TRGET_AT') === '1' &&
                            '필수 입력항목입니다.',
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                              disabled={
                                currentValues['RECALL_TRGET_AT'] === '0'
                              }
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="STMD_AT"
                        rules={{ required: '필수 입력항목입니다.' }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                              onChange={(e) => {
                                field.onChange(e);
                                setValue('vehicleInformation.STMD_AT', e);
                                if (e === 'N') {
                                  setValue('UNLAW_STMD_AT', '0');
                                }
                              }}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="UNLAW_STMD_AT"
                        rules={{
                          required:
                            _.get(currentValues, 'STMD_AT') === 'Y' &&
                            formRequired &&
                            '필수 입력항목입니다.',
                          validate: (val) => {
                            if (
                              _.get(currentValues, 'STMD_AT') === 'Y' &&
                              val === '0' &&
                              formRequired
                            ) {
                              return '필수 입력항목입니다.';
                            }
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                              disabled={currentValues['STMD_AT'] === 'N'}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="UNLAW_STMD_IEM_SE_CODE"
                        defaultValue="0"
                        rules={{
                          required:
                            _.get(currentValues, 'STMD_AT') === 'Y' &&
                            formRequired &&
                            '필수 입력항목입니다.',
                          validate: (val) => {
                            if (
                              formRequired &&
                              _.get(currentValues, 'STMD_AT') === 'Y' &&
                              val === '0'
                            ) {
                              return '필수 입력항목입니다.';
                            }
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                              disabled={currentValues['STMD_AT'] === 'N'}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="PRPOS_CHANGE_SE_AT"
                        rules={{ required: '필수 입력항목입니다.' }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                setValue(
                                  'vehicleInformation.PRPOS_CHANGE_SE_AT',
                                  e
                                );
                                if (e === 'N') {
                                  setValue('PRPOS_CHANGE_SE_CODE', '0');
                                }
                              }}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>

                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="PRPOS_CHANGE_SE_CODE"
                        rules={{
                          required:
                            formRequired &&
                            _.get(currentValues, 'PRPOS_CHANGE_SE_AT') ===
                              'Y' &&
                            '필수 입력항목입니다.',
                          validate: (val) => {
                            if (
                              formRequired &&
                              _.get(currentValues, 'PRPOS_CHANGE_SE_AT') ===
                                'Y' &&
                              val === '0'
                            ) {
                              return '필수 입력항목입니다.';
                            }
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                              disabled={
                                currentValues['PRPOS_CHANGE_SE_AT'] === 'N'
                              }
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-4 mb-2">
                      <Controller
                        control={control}
                        name="FLUD_AT"
                        rules={{ required: '필수 입력항목입니다.' }}
                        render={({ field, fieldState }) => (
                          <>
                            <Grouped.TitledRadioButton
                              title={baseTitles[field.name]}
                              id={field.name}
                              {...field}
                              error={errors[field.name]}
                              items={baseValueItems[field.name]}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 sm:col-12 lg:col-3 mb-2" />
                    <div className="col-12 sm:col-12 lg:col-3 mb-2" />
                  </div>
                </Panel>
              </div>

              <div className="col-12">
                <Panel
                  header="주요장치 상태 및 누유, 누수 표시"
                  className="shadow-2"
                >
                  <div className="grid">
                    <div className="col-12 lg:col-6">
                      <div className="flex flex-auto flex-column gap-1">
                        <Panel header="자기진단">
                          <div className="flex flex-auto flex-column gap-1">
                            <Controller
                              control={control}
                              name="ENGINE_STBLT_AT"
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckRadio
                                  title="원동기"
                                  id={field.name}
                                  {...field}
                                  error={errors[field.name]}
                                  items={baseValueItems[field.name]}
                                />
                              )}
                            />

                            <Controller
                              control={control}
                              name="GRBX_STBLT_AT"
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckRadio
                                  title="변속기"
                                  id={field.name}
                                  {...field}
                                  error={errors[field.name]}
                                  items={baseValueItems[field.name]}
                                />
                              )}
                            />
                          </div>
                        </Panel>

                        <Panel header="원동기" className="mt-3">
                          <div className="flex flex-auto flex-column gap-1">
                            <>
                              <Controller
                                control={control}
                                name="s001"
                                render={({ field, fieldState }) => (
                                  <Grouped.TitledCheckRadio
                                    title="작동상태 (공회전)"
                                    id={field.name}
                                    {...field}
                                    error={errors[field.name]}
                                    items={statusValueItems[field.name]}
                                  />
                                )}
                              />

                              <Panel
                                header="오일누유"
                                className="cm-inner-panel"
                              >
                                <div className="flex flex-auto flex-column gap-1">
                                  {['s057', 's003', 's058'].map((item, idx) => (
                                    <Controller
                                      key={`key_${item}_${idx}`}
                                      control={control}
                                      name={item}
                                      render={({ field, fieldState }) => (
                                        <Grouped.TitledCheckRadio
                                          title={statusTitles[item]}
                                          id={item}
                                          {...field}
                                          error={errors[item]}
                                          items={statusValueItems[item]}
                                        />
                                      )}
                                    />
                                  ))}
                                </div>
                              </Panel>

                              <Controller
                                control={control}
                                name="s005"
                                render={({ field, fieldState }) => (
                                  <Grouped.TitledCheckRadio
                                    title="오일 유량"
                                    id={field.name}
                                    {...field}
                                    error={errors[field.name]}
                                    items={statusValueItems[field.name]}
                                  />
                                )}
                              />
                            </>
                            {/* )} */}

                            <Panel
                              header="냉각수 누수"
                              className="cm-inner-panel"
                            >
                              <div className="flex flex-auto flex-column gap-1">
                                {['s007', 's008', 's009', 's010'].map(
                                  (item, idx) => {
                                    return (
                                      <Controller
                                        key={`key_${item}_${idx}`}
                                        control={control}
                                        name={item}
                                        render={({ field, fieldState }) => (
                                          <Grouped.TitledCheckRadio
                                            title={statusTitles[item]}
                                            id={item}
                                            {...field}
                                            error={errors[item]}
                                            items={statusValueItems[item]}
                                          />
                                        )}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </Panel>

                            <Controller
                              control={control}
                              name="s011"
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckRadio
                                  title={statusTitles[field.name]}
                                  id={field.name}
                                  {...field}
                                  error={errors[field.name]}
                                  items={statusValueItems[field.name]}
                                />
                              )}
                            />
                          </div>
                        </Panel>

                        <Panel header="변속기" className="mt-3">
                          <div className="flex flex-auto flex-column gap-1">
                            <Panel
                              header="자동변속기(A/T)"
                              className="cm-inner-panel"
                            >
                              <div className="flex flex-auto flex-column gap-1">
                                {['s012', 's013', 's016'].map((item, idx) => {
                                  return (
                                    <Controller
                                      key={`key_${item}_${idx}`}
                                      control={control}
                                      name={item}
                                      render={({ field, fieldState }) => (
                                        <Grouped.TitledCheckRadio
                                          title={statusTitles[item]}
                                          id={item}
                                          {...field}
                                          error={errors[item]}
                                          items={statusValueItems[item]}
                                        />
                                      )}
                                    />
                                  );
                                })}
                              </div>
                            </Panel>

                            <Panel
                              header="수동변속기(M/T)"
                              className="cm-inner-panel"
                            >
                              <div className="flex flex-auto flex-column gap-1">
                                {['s017', 's018', 's019', 's020'].map(
                                  (item, idx) => (
                                    <Controller
                                      key={`key_${item}_${idx}`}
                                      control={control}
                                      name={item}
                                      render={({ field, fieldState }) => (
                                        <Grouped.TitledCheckRadio
                                          title={statusTitles[item]}
                                          id={item}
                                          {...field}
                                          error={errors[item]}
                                          items={statusValueItems[item]}
                                        />
                                      )}
                                    />
                                  )
                                )}
                              </div>
                            </Panel>
                          </div>
                        </Panel>
                      </div>
                    </div>

                    <div className="col-12 lg:col-6">
                      <Panel header="동력전달">
                        <div className="flex flex-auto flex-column gap-1">
                          {['s021', 's022', 's023', 's060'].map((item, idx) => {
                            return (
                              <Controller
                                key={`key_${item}_${idx}`}
                                control={control}
                                name={item}
                                render={({ field, fieldState }) => (
                                  <Grouped.TitledCheckRadio
                                    title={statusTitles[item]}
                                    id={item}
                                    {...field}
                                    error={errors[item]}
                                    items={statusValueItems[item]}
                                  />
                                )}
                              />
                            );
                          })}
                        </div>
                      </Panel>

                      <Panel header="조향" className="mt-3">
                        <div className="flex flex-auto flex-column gap-1">
                          <Controller
                            control={control}
                            name="s024"
                            render={({ field, fieldState }) => (
                              <Grouped.TitledCheckRadio
                                title={statusTitles[field.name]}
                                id={field.name}
                                {...field}
                                error={errors[field.name]}
                                items={statusValueItems[field.name]}
                              />
                            )}
                          />
                          {/* )} */}

                          <Panel header="작동상태" className="cm-inner-panel">
                            <div className="flex flex-auto flex-column gap-1">
                              {['s026', 's025', 's061', 's062', 's027'].map(
                                (item, idx) => {
                                  // if (
                                  //   _.includes(['s026'], item) &&
                                  //   performanceCheckUtil.Checker.Fuel.isElectronic(
                                  //     currentValues
                                  //   )
                                  // )
                                  //   return null;
                                  return (
                                    <Controller
                                      key={`key_${item}_${idx}`}
                                      control={control}
                                      name={item}
                                      render={({ field, fieldState }) => (
                                        <Grouped.TitledCheckRadio
                                          title={statusTitles[item]}
                                          id={item}
                                          {...field}
                                          error={errors[item]}
                                          items={statusValueItems[item]}
                                        />
                                      )}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </Panel>
                        </div>
                      </Panel>

                      <Panel header="제동" className="mt-3">
                        <div className="flex flex-auto flex-column gap-1">
                          {['s059', 's029', 's030'].map((item, idx) => (
                            <Controller
                              key={`key_${item}_${idx}`}
                              control={control}
                              name={item}
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckRadio
                                  title={statusTitles[item]}
                                  id={item}
                                  {...field}
                                  error={errors[item]}
                                  items={statusValueItems[item]}
                                />
                              )}
                            />
                          ))}
                        </div>
                      </Panel>

                      <Panel header="전기" className="mt-3">
                        <div className="flex flex-auto flex-column gap-1">
                          {['s031', 's055', 's032', 's033', 's034', 's036'].map(
                            (item, idx) => {
                              return (
                                <Controller
                                  key={`key_${item}_${idx}`}
                                  control={control}
                                  name={item}
                                  render={({ field, fieldState }) => (
                                    <Grouped.TitledCheckRadio
                                      title={statusTitles[item]}
                                      id={item}
                                      {...field}
                                      error={errors[item]}
                                      items={statusValueItems[item]}
                                    />
                                  )}
                                />
                              );
                            }
                          )}
                        </div>
                      </Panel>

                      <Panel header="고전원 전기장치" className="mt-3">
                        <div className="flex flex-auto flex-column gap-1">
                          {['s063', 's064', 's065'].map((item, idx) => (
                            <Controller
                              key={`key_${item}_${idx}`}
                              control={control}
                              name={item}
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckRadio
                                  title={statusTitles[item]}
                                  id={item}
                                  {...field}
                                  error={errors[item]}
                                  items={statusValueItems[item]}
                                />
                              )}
                            />
                          ))}
                        </div>
                      </Panel>

                      <Panel header="연료" className="mt-3">
                        <div className="flex flex-auto flex-column gap-1">
                          <Controller
                            control={control}
                            name="s035"
                            render={({ field, fieldState }) => (
                              <Grouped.TitledCheckRadio
                                title={statusTitles[field.name]}
                                id={field.name}
                                {...field}
                                error={errors[field.name]}
                                items={statusValueItems[field.name]}
                              />
                            )}
                          />
                        </div>
                      </Panel>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6">
            <div className="grid">
              <div className="col-12">
                <Panel header="자동차의 상태표시" className="shadow-2">
                  <div className="grid">
                    <div className="col-12 lg:col-8">
                      <Panel
                        headerTemplate={
                          <div className="p-panel-header">
                            <div className="flex flex-column align-items-start justify-content-center gap-1">
                              <span className="p-panel-title">
                                교환, 판금 등 이상 부위
                              </span>
                              <span className="text-sm">
                                * 승용차 외에는 승용차에 준하여 표기
                              </span>
                            </div>
                            <div className="flex align-items-center justify-content-start">
                              <Controller
                                control={control}
                                name="vehicleInformation.LOSS_STATUS"
                                render={({ field }) => (
                                  <>
                                    <label
                                      htmlFor="lossStatus"
                                      className="font-semibold mr-2"
                                    >
                                      전손(분손) 처리 여부
                                    </label>
                                    <InputSwitch
                                      checked={
                                        _.get(
                                          currentValues,
                                          'vehicleInformation.LOSS_STATUS'
                                        ) === YN.YES
                                      }
                                      {...field}
                                      onChange={({ value }) => {
                                        const lossStatus = value
                                          ? YN.YES
                                          : YN.NO;
                                        field.onChange(lossStatus);
                                        checkAbnormalPart({
                                          currentValues,
                                          lossStatus,
                                        });
                                        if (lossStatus === 'Y') {
                                          const [
                                            insurancePriceVAT,
                                            insurancePrice,
                                          ] = setInsurancePrice({
                                            warrantyType: '1',
                                          });
                                          calculatePrices({
                                            checkPriceVAT,
                                            checkPrice,
                                            insurancePriceVAT,
                                            insurancePrice,
                                          });
                                          setValue(
                                            'paymentInformation.ASSRNC_TY_SE_CODE',
                                            '1'
                                          );
                                          setValue('ASSRNC_TY_SE_CODE', '1');
                                          setValue(
                                            'paymentInformation.insurancePricePaidYN',
                                            'Y'
                                          );
                                        } else {
                                          const [
                                            insurancePriceVAT,
                                            insurancePrice,
                                          ] = setInsurancePrice({
                                            warrantyType: '2',
                                          });
                                          calculatePrices({
                                            checkPriceVAT,
                                            checkPrice,
                                            insurancePriceVAT,
                                            insurancePrice,
                                          });
                                          setValue(
                                            'paymentInformation.ASSRNC_TY_SE_CODE',
                                            '2'
                                          );
                                          setValue('ASSRNC_TY_SE_CODE', '2');
                                          setValue(
                                            'paymentInformation.insurancePricePaidYN',
                                            'N'
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        }
                      >
                        <div className="grid">
                          <div className="col-12">
                            <div className="card grid py-1">
                              {STATUS_ICON.map((el, idx) => (
                                <div className="col-6 lg:col-2" key={idx}>
                                  <div className="flex flex-auto align-items-center justify-content-start gap-2">
                                    <Badge
                                      value={el.value}
                                      severity={el.icon}
                                    />
                                    <span className="font-bold">{el.text}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 flex flex-auto align-items-center justify-content-center">
                            <div
                              className="relative py-2 bg-no-repeat bg-contain bg-center"
                              style={{
                                minWidth: 220,
                                width: 220,
                                minHeight: 400,
                                height: 400,
                                backgroundImage:
                                  'url(/assets/blueprint/bp-left.png)',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            >
                              {PARTS_LEFT.map((item, idx) => {
                                return (
                                  <Controller
                                    key={`PRM_${item.partPoint}_${idx}`}
                                    control={control}
                                    name={item.partPoint}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                      <PartRecordMenu
                                        id={field.name}
                                        {...field}
                                        index={idx}
                                        partItem={item}
                                        menuRef={leftMenuRefs}
                                        guideRef={leftGuideRefs}
                                        selected={field.value}
                                      />
                                    )}
                                  />
                                );
                              })}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 flex flex-auto align-items-center justify-content-center">
                            <div
                              className="relative py-2 bg-no-repeat bg-contain bg-center"
                              style={{
                                minWidth: 220,
                                width: 220,
                                minHeight: 400,
                                height: 400,
                                backgroundImage:
                                  'url(/assets/blueprint/bp-right.png)',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            >
                              {PARTS_RIGHT.map((item, idx) => (
                                <Controller
                                  key={`PRM_${item.partPoint}_${idx}`}
                                  control={control}
                                  name={item.partPoint}
                                  defaultValue=""
                                  render={({ field, fieldState }) => (
                                    <PartRecordMenu
                                      id={field.name}
                                      {...field}
                                      index={idx}
                                      partItem={item}
                                      menuRef={rightMenuRefs}
                                      guideRef={rightGuideRefs}
                                      selected={field.value}
                                    />
                                  )}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 flex flex-auto align-items-center justify-content-center">
                            <div
                              className="relative py-2 bg-no-repeat bg-contain bg-center"
                              style={{
                                minWidth: 300,
                                width: 300,
                                minHeight: 400,
                                height: 400,
                                backgroundImage:
                                  'url(/assets/blueprint/bp-top.png)',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            >
                              {PARTS_TOP.map((item, idx) => (
                                <Controller
                                  key={`PRM_${item.partPoint}_${idx}`}
                                  control={control}
                                  name={item.partPoint}
                                  defaultValue=""
                                  render={({ field, fieldState }) => (
                                    <PartRecordMenu
                                      id={field.name}
                                      {...field}
                                      index={idx}
                                      partItem={item}
                                      menuRef={topMenuRefs}
                                      guideRef={topGuideRefs}
                                      selected={field.value}
                                    />
                                  )}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="col-12 lg:col-6 flex flex-auto align-items-center justify-content-center">
                            <div
                              className="relative py-2 bg-no-repeat bg-contain bg-center"
                              style={{
                                minWidth: 220,
                                width: 220,
                                minHeight: 400,
                                height: 400,
                                backgroundImage:
                                  'url(/assets/blueprint/bp-bottom.png)',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                              }}
                            >
                              {PARTS_BOTTOM.map((item, idx) => (
                                <Controller
                                  key={`PRM_${item.partPoint}_${idx}`}
                                  control={control}
                                  name={item.partPoint}
                                  defaultValue=""
                                  render={({ field, fieldState }) => (
                                    <PartRecordMenu
                                      id={field.name}
                                      {...field}
                                      index={idx}
                                      partItem={item}
                                      menuRef={bottomMenuRefs}
                                      guideRef={bottomGuideRefs}
                                      selected={field.value}
                                    />
                                  )}
                                />
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="grid mt-3">
                          {(_.get(currentValues, 's043') === '3' ||
                            _.get(currentValues, 's044') === '3' ||
                            _.get(currentValues, 's046') === '3') && (
                            <div className="card col-12 mx-2">
                              <div>
                                <strong>쿼터패널, 사이드실패널,</strong> 또는{' '}
                                <strong>크로스멤버</strong>에 판금이 확인되는
                                경우 사고 이력에 영향을 줄 수 있습니다. 사고
                                이력을 <strong>‘있음’</strong>으로 수정
                                하시겠습니까?
                              </div>
                              <div className="flex justify-content-center gap-2 mt-2">
                                <Button.Default
                                  type="button"
                                  label={'사고이력 수정'}
                                  icon="pi pi-check"
                                  onClick={() => {
                                    checkAbnormalPart({
                                      currentValues,
                                      accidentUpdateRequest: true,
                                    });
                                  }}
                                />
                                <Button.Default
                                  type="button"
                                  label={'취소'}
                                  icon="pi pi-check"
                                  className="p-button-outlined"
                                  onClick={() => {
                                    checkAbnormalPart({
                                      currentValues,
                                      accidentUpdateRequest: false,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          <div className="col-12 lg:col-6">
                            <Controller
                              control={control}
                              name="ACDNT_AT"
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckbox
                                  title={baseTitles[field.name]}
                                  id={field.name}
                                  {...field}
                                  error={errors[field.name]}
                                  items={baseValueItems[field.name]}
                                  // readOnly
                                />
                              )}
                            />
                          </div>

                          <div className="col-12 lg:col-6">
                            <Controller
                              control={control}
                              name="SIMPL_REPAIR_AT"
                              render={({ field, fieldState }) => (
                                <Grouped.TitledCheckbox
                                  title={baseTitles[field.name]}
                                  id={field.name}
                                  {...field}
                                  error={errors[field.name]}
                                  items={baseValueItems[field.name]}
                                  // readOnly
                                />
                              )}
                            />
                          </div>

                          <div className="col-12">
                            <Panel header="교환, 판금 등 이상 부위">
                              <div className="flex flex-auto flex-column gap-1">
                                <Panel header="외판부위">
                                  <div className="flex flex-auto flex-column gap-1">
                                    <div className="p-inputgroup">
                                      <span
                                        className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        1랭크
                                      </span>
                                      <div className="p-inputgroup-addon w-full bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                                        {RankComponent(ONE_RANK, currentValues)}
                                      </div>
                                    </div>

                                    <div className="p-inputgroup">
                                      <span
                                        className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        2랭크
                                      </span>
                                      <div className="p-inputgroup-addon w-full bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                                        {RankComponent(TOW_RANK, currentValues)}
                                      </div>
                                    </div>
                                  </div>
                                </Panel>

                                <Panel header="주요골격">
                                  <div className="flex flex-auto flex-column gap-1">
                                    <div className="p-inputgroup">
                                      <span
                                        className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        A랭크
                                      </span>
                                      <div className="p-inputgroup-addon w-full bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                                        {RankComponent(A_RANK, currentValues)}
                                      </div>
                                    </div>
                                    <div className="p-inputgroup">
                                      <span
                                        className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        B랭크
                                      </span>
                                      <div className="p-inputgroup-addon w-full bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                                        {RankComponent(
                                          [
                                            {
                                              partNumber: '12',
                                              label: '사이드멤버',
                                            },
                                            {
                                              partNumber: '13',
                                              label: '휠하우스',
                                            },
                                          ],
                                          currentValues
                                        )}

                                        <div>
                                          <div className="flex flex-auto flex-wrap align-items-center justify-content-start gap-1">
                                            {RankComponent(
                                              [
                                                {
                                                  partNumber: '14',
                                                  label: '필러패널',
                                                },
                                              ],
                                              currentValues
                                            )}

                                            <div className="flex flex-auto flex-wrap align-items-center justify-content-start gap-1">
                                              {RankComponent(
                                                [
                                                  {
                                                    partNumber: '14A',
                                                    label: 'A',
                                                  },
                                                  {
                                                    partNumber: '14B',
                                                    label: 'B',
                                                  },
                                                  {
                                                    partNumber: '14C',
                                                    label: 'C',
                                                  },
                                                ],
                                                currentValues,
                                                false
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {RankComponent(
                                          [
                                            {
                                              partNumber: '19',
                                              label: '패키지트레이',
                                            },
                                          ],
                                          currentValues
                                        )}
                                      </div>
                                    </div>
                                    <div className="p-inputgroup">
                                      <span
                                        className="p-inputgroup-addon w-full font-medium text-center min-w-3 w-5 max-w-6"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        C랭크
                                      </span>
                                      <div className="p-inputgroup-addon w-full bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
                                        {RankComponent(
                                          [
                                            {
                                              partNumber: '15',
                                              label: '대쉬패널',
                                            },
                                            {
                                              partNumber: '16',
                                              label: '플로어패널',
                                            },
                                          ],
                                          currentValues
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Panel>
                              </div>
                            </Panel>
                          </div>
                        </div>
                      </Panel>
                    </div>

                    <div className="col-12 lg:col-4">
                      <Panel header="차량정비이력" className="mb-3">
                        <Accordion multiple>
                          {_.map(
                            _.sortBy(workDetails, 'workRequestDate'),
                            (workDetail, idx) => {
                              const requestDate = dayjs(
                                _.get(workDetail, 'workRequestDate')
                              ).format('YYYY-MM-DD');
                              const completeDate = dayjs(
                                _.get(workDetail, 'workCompleteDate')
                              ).format('YYYY-MM-DD');
                              return (
                                <AccordionTab
                                  header={<p>{requestDate}</p>}
                                  key={`repair_${idx}`}
                                >
                                  <p>
                                    <strong>수리 요청일 :</strong> {requestDate}
                                  </p>
                                  <p>
                                    <strong>수리 완료일 :</strong>{' '}
                                    {completeDate}
                                  </p>
                                  {_.map(
                                    _.get(workDetail, 'workItems'),
                                    (workItem, idx) => {
                                      return (
                                        <p key={`repair_text_${idx}`}>
                                          {workItem?.fullText}
                                        </p>
                                      );
                                    }
                                  )}
                                </AccordionTab>
                              );
                            }
                          )}
                        </Accordion>
                      </Panel>
                      {/* 카히스토리 */}
                      <Panel
                        headerTemplate={
                          <div className="p-panel-header flex justify-content-between">
                            <span
                              className="p-panel-title "
                              style={{
                                wordBreak: 'keep-all',
                              }}
                            >
                              보험이력
                            </span>
                          </div>
                        }
                        className="mb-3"
                      >
                        <div className="flex justify-content-center">
                          {historyResultMsg === '' ? (
                            <>
                              <Button.Default
                                type="button"
                                loading={historyLoading}
                                className="w-full"
                                label="보기"
                                disabled={encodingImageSrc.length === 0}
                                onClick={() => {
                                  if (!encodingImageSrc) return;
                                  sessionStorage.setItem(
                                    'insurance',
                                    JSON.stringify(encodingImageSrc)
                                  );
                                  CommonUtil.Window.popup(
                                    '/performance-check/form/insuranceHistory',
                                    '',
                                    window.innerWidth * 0.9,
                                    window.innerHeight * 0.9
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <p
                              style={{
                                color: '#D32F2F',
                                fontWeight: '600',
                                textAlign: 'center',
                              }}
                            >
                              {historyResultMsg}
                              <br />
                              보험이력 조회 실패
                            </p>
                          )}
                        </div>
                      </Panel>
                      {/* 나이스디앤알 */}
                      {/* <Panel
                        headerTemplate={
                          <div className="p-panel-header flex justify-content-between">
                            <span
                              className="p-panel-title"
                              style={{
                                wordBreak: 'keep-all',
                              }}
                            >
                              카센터 정비이력
                            </span>
                            <Button.Default
                              type="button"
                              label="조회"
                              className="p-button-info h-auto flex justify-content-center"
                              // icon="pi pi-search"
                              style={{
                                wordBreak: 'keep-all',
                              }}
                              onClick={() => {
                                !!currentValues.licensePlateNumber
                                  ? setShowConfirmPopup((ps) => ({
                                      ...ps,
                                      niceDNR: true,
                                    }))
                                  : window.cerp.toast.warn(
                                      '차량정보조회를 먼저 해주세요.'
                                    );
                              }}
                            />
                          </div>
                        }
                        className="mb-3"
                      >
                        <Accordion multiple>
                          {_.map(accidentHistory.niceDNR, (dateData) => {
                            const { inDay, outDay, workList } = dateData;

                            return (
                              <AccordionTab
                                header={
                                  <div>
                                    <span>{inDay}</span>{' '}
                                  </div>
                                }
                                key={`car_history_date_${inDay}`}
                              >
                                <p>
                                  <strong>입차일 : </strong> {inDay}
                                </p>
                                <p>
                                  <strong>출차일 : </strong> {outDay}
                                </p>
                                {_.map(workList, (part, idx) => {
                                  return (
                                    <p key={`car_history_work_${idx}`}>
                                      {part}
                                    </p>
                                  );
                                })}
                              </AccordionTab>
                            );
                          })}
                        </Accordion>
                      </Panel> */}
                    </div>
                  </div>
                </Panel>
              </div>

              <div className="col-12">
                <Panel header="사진 및 특이사항" className="shadow-2">
                  <div className="grid">
                    <div className="field col-12">
                      <label>사진</label>
                      <div className="grid">
                        <div className="col-12">
                          <div
                            {...getRootProps({
                              className:
                                'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                              style: { wordBreak: 'keep-all' },
                            })}
                          >
                            <input {...getInputProps()} />
                            <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                              <i
                                className="pi pi-arrow-circle-down"
                                style={{ fontSize: '1.5rem' }}
                              />
                              이 영역에 점검 사진을 끌어다 놓거나, 영역을
                              클릭하여 사진을 선택하세요.
                              <p>(최대 20개의 파일을 업로드할 수 있습니다.)</p>
                            </div>
                          </div>
                        </div>
                        {fileItems.length > 0 && (
                          <div className="col-12 px-3">
                            <div className="flex flex-auto flex-column gap-2">
                              {fileItems.map((item, idx) => {
                                const { state = FileState.Loaded } = item;
                                if (state !== FileState.Deleted) {
                                  return (
                                    <div
                                      key={`FILE_${idx}`}
                                      className="grid border-1 border-300 border-round-sm mt-0"
                                    >
                                      <div className="col-12 sm:col-9">
                                        <div className="flex flex-auto align-items-center justify-content-start">
                                          {_.has(item, 'photoId') ? (
                                            <>
                                              <Image
                                                src={`${process.env.REACT_APP_S3_BASE_URL}${item.filePath}`}
                                                alt={item.originalFileName}
                                                preview
                                                imageClassName="border-round border-1 border-200"
                                                imageStyle={{
                                                  width: 44,
                                                  height: 44,
                                                }}
                                              />
                                              <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                                <span>
                                                  {item.originalFileName}
                                                </span>
                                                <Badge
                                                  value={`${filesize(
                                                    item.fileSize,
                                                    {
                                                      round: 1,
                                                      standard: 'jedec',
                                                    }
                                                  )}`}
                                                  severity="info"
                                                />
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <Image
                                                src={item.file.preview}
                                                alt={item.file.name}
                                                preview
                                                imageClassName="border-round border-1 border-200"
                                                imageStyle={{
                                                  width: 44,
                                                  height: 44,
                                                }}
                                              />
                                              <div className="mx-3 flex flex-column align-items-start justify-content-center gap-1">
                                                <span>{item.file.name}</span>
                                                <Badge
                                                  value={`${filesize(
                                                    item.file.size,
                                                    {
                                                      round: 1,
                                                      standard: 'jedec',
                                                    }
                                                  )}`}
                                                  severity="info"
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-12 sm:col-3 text-right">
                                        <Dropdown
                                          className="mr-2"
                                          // filter
                                          value={fileItems[idx].partCode}
                                          options={[
                                            {
                                              name: '1.정면',
                                              code: 'OUT_FRONT',
                                            },
                                            {
                                              name: '2.후면',
                                              code: 'OUT_REAR',
                                            },
                                            {
                                              name: '3.성능점검 스캔본',
                                              code: 'SCAN',
                                            },
                                            { name: '4.기타', code: 'ETC' },
                                          ]}
                                          onChange={(e) => {
                                            const clonedFiles = [...fileItems];
                                            const clonedFile = clonedFiles[idx];

                                            if (_.has(clonedFile, 'photoId')) {
                                              clonedFiles[idx] = {
                                                ...clonedFile,
                                                partCode: e.value,
                                                state: FileState.Updated,
                                              };
                                            } else {
                                              clonedFiles[idx] = {
                                                ...clonedFile,
                                                partCode: e.value,
                                              };
                                            }
                                            setFileItems(clonedFiles);
                                          }}
                                          optionLabel="name"
                                          optionValue="code"
                                          placeholder="종류 선택"
                                        />
                                        <Button.Default
                                          type="button"
                                          icon="pi pi-trash"
                                          className="p-button-danger p-button-outlined"
                                          onClick={() => {
                                            const clonedFiles = [...fileItems];
                                            const clonedFile = clonedFiles[idx];

                                            if (_.has(clonedFile, 'photoId')) {
                                              clonedFiles[idx] = {
                                                ...clonedFile,
                                                state: FileState.Deleted,
                                              };
                                            } else {
                                              clonedFiles.splice(idx, 1);
                                            }

                                            setFileItems(clonedFiles);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="field col-12 mb-0">
                      <Controller
                        control={control}
                        name="ETC_MATTER"
                        render={({ field, fieldState }) => (
                          <>
                            <label htmlFor="range">
                              {baseTitles[field.name]}
                            </label>
                            <InputTextarea
                              id={field.name}
                              {...field}
                              className="w-full"
                              autoResize={true}
                              rows={3}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Panel>
              </div>

              <div className="col-12">
                <Panel header="비고사항" className="shadow-2">
                  <div className="grid">
                    <div className="field col-12">
                      <Controller
                        control={control}
                        name="note"
                        render={({ field, fieldState, formState }) => (
                          <InputTextarea
                            id={field.name}
                            className="w-full mb-1"
                            {...field}
                            rows={4}
                            autoResize
                          />
                        )}
                      />
                      <small>
                        (참고 : 비고사항 내역은 성능점검에 포함되지 않습니다.)
                      </small>
                    </div>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Panel className="shadow-3">
              <div className="flex flex-auto flex-wrap align-items-center justify-content-center gap-1 lg:gap-3">
                {menus.reverse().map((menu, idx) => {
                  return (
                    <Button.Default
                      key={menu.key}
                      type="button"
                      icon={menu.icon}
                      label={menu.label}
                      disabled={menu?.disabled}
                      className={classNames(menu.buttonClassName || '', {
                        'p-button-outlined': !menu.isPrimary,
                      })}
                      onClick={menu.command}
                    />
                  );
                })}
              </div>
            </Panel>
          </div>
        </div>

        <div
          className="performance-check-speeddial"
          style={{ position: 'relative' }}
        >
          <Tooltip
            target=".performance-check-speeddial .speeddial-right .p-speeddial-action"
            position="left"
          />
          <SpeedDial
            model={menus}
            showIcon="pi pi-bars"
            direction="up"
            className="speeddial-right"
            style={{ position: 'fixed', bottom: '1.6rem', right: '1.6rem' }}
            buttonStyle={{ width: '3.6rem', height: '3.6rem' }}
          />
        </div>
      </form>
      <ConfirmDialog
        visible={showConfirmPopup['reset']}
        header="확인"
        message="현재 입력한 내용을 초기화 하시겠습니까?"
        icon="pi pi-exclamation-triangle"
        acceptLabel="네"
        acceptIcon="pi pi-check"
        accept={() => {
          reset();
          setFileItems([]);
        }}
        rejectLabel="아니오"
        reject={() => setShowConfirmPopup((ps) => ({ ...ps, reset: false }))}
        onHide={() => setShowConfirmPopup((ps) => ({ ...ps, reset: false }))}
      />

      <ConfirmDialog
        visible={showConfirmPopup['submit']}
        header="성능점검 작성확인"
        message="성능점검 작성을 완료하시겠습니까?"
        icon="pi pi-exclamation-triangle"
        acceptLabel="네"
        acceptIcon="pi pi-check"
        accept={() => {
          setFormRequired(true);
          handleSubmit((formData) => onSubmit(SubmitType.Complete, formData))();
        }}
        rejectLabel="아니오"
        reject={() => setShowConfirmPopup((ps) => ({ ...ps, submit: false }))}
        onHide={() => setShowConfirmPopup((ps) => ({ ...ps, submit: false }))}
      />

      <ConfirmDialog
        visible={showConfirmPopup['carHistory'] || showConfirmPopup['niceDNR']}
        header="비용 발생 안내"
        message="해당 정보 조회는 별도의 비용이 발생합니다. 계속 진행하시겠습니까?"
        icon="pi pi-exclamation-triangle"
        acceptLabel="네"
        acceptIcon="pi pi-check"
        accept={async () => {
          if (showConfirmPopup['carHistory']) {
            setShowConfirmPopup((ps) => ({ ...ps, carHistory: false }));
            // await getCarHistoryDatas();
          } else {
            setShowConfirmPopup((ps) => ({ ...ps, niceDNR: false }));
            await getNiceDNRDatas();
          }
        }}
        rejectLabel="아니오"
        reject={() =>
          setShowConfirmPopup((ps) => ({
            ...ps,
            carHistory: false,
            niceDNR: false,
          }))
        }
        onHide={() =>
          setShowConfirmPopup((ps) => ({
            ...ps,
            carHistory: false,
            niceDNR: false,
          }))
        }
      />

      <ConfirmDialog
        visible={showConfirmPopup['delete']}
        header="성능점검 삭제확인"
        message="성능점검을 삭제하시겠습니까?"
        icon="pi pi-trash"
        acceptLabel="네"
        acceptIcon="pi pi-check"
        acceptClassName="p-button-danger"
        accept={async () => {
          const { data, error } = await performanceCheckService.delete(dataId);

          if (data) {
            const { molitState, performanceCheckData } = data;

            if (molitState === MolitState.Success) {
              window.close();
              setTimeout(() => {
                window.cerp.toast.success(
                  '성능점검 삭제 완료',
                  `성능점검번호 [${performanceCheckData.statementNumber}]가 삭제되었습니다.`
                );
              }, 500);
            } else if (molitState === MolitState.Failed) {
              setTimeout(() => {
                window.cerp.dialog.warn(
                  '성능점검 삭제 실패',
                  `성능점검번호 [${performanceCheckData.statementNumber}]를 삭제하지 못했습니다. 다시 시도해 주세요.`,
                  `[${_.get(data, 'molitResultCode')}] ${_.get(
                    data,
                    'molitResultDetails'
                  )}`
                );
              }, 350);
            }
          }

          if (error) {
            setTimeout(() => {
              window.cerp.dialog.error(
                '성능점검 삭제 실패',
                `[${error?.code}] ${error?.message}`
              );
            }, 350);
          }
        }}
        rejectLabel="아니오"
        reject={() => setShowConfirmPopup((ps) => ({ ...ps, delete: false }))}
        onHide={() => setShowConfirmPopup((ps) => ({ ...ps, delete: false }))}
      />

      <Dialog
        blockScroll
        closable={false}
        visible={transferError.code !== ''}
        header={
          <div className="flex align-items-center">
            <span className="pi pi-exclamation-triangle mr-2 text-red-500 text-xl" />
            <span>성능 점검 국토부 전송 실패</span>
          </div>
        }
        footer={
          <Button.Default
            label="확인"
            icon="pi pi-check"
            onClick={() => {
              window.close();
            }}
          />
        }
      >
        <div>
          <span>{`성능점검번호 [${statementNumber}]의 상세정보를 국토부에 전송하였으나 아래와 같은 오류가 발생하였습니다.`}</span>
          <div className="p-2 surface-100 border-round-sm	text-center m-3">{`[${transferError.code}] ${transferError.details}`}</div>
          <span>상세 정보를 재 작성하여 다시 시도해 주세요. </span>
          <span>아래 확인 버튼을 눌러 창을 닫아주세요.</span>
        </div>
      </Dialog>

      {isDialogOpened.userSearch && (
        <SearchDialog.User
          userInfo={myInfoLoadable.contents}
          preSelected={{
            associationId: _.get(currentValues, 'associationId'),
            enterpriseId: _.get(currentValues, 'enterpriseId'),
            shopId: _.get(currentValues, 'shopId'),
          }}
          onHide={async (userData) => {
            setIsDialogOpened((ps) => ({ ...ps, userSearch: false }));

            if (userData) {
              const [{ association, enterprise, shop }, inspector] =
                separateUserData(userData);

              const [
                [checkPriceTable],
                [insurancePriceTables, insuranceCompany, insurances],
              ] = await Promise.all([
                getCheckPriceTable({
                  associationId: _.get(association, 'associationId'),
                  enterpriseId: _.get(enterprise, 'enterpriseId'),
                  shopId: _.get(shop, 'shopId'),
                }),
                getInsurancePriceTables({
                  associationId: _.get(association, 'associationId'),
                  enterpriseId: _.get(enterprise, 'enterpriseId'),
                  shopId: _.get(shop, 'shopId'),
                }),
              ]);

              setCodes((ps) => ({
                ...ps,
                insurancePriceTables,
                insurances,
                checkPriceTable,
              }));

              //* check price table 에서 item의 가장 첫번째 요소를 paymentinformation.checkpriceitemid에 할당하기

              setValue('association', association);
              setValue('enterprise', enterprise);
              setValue('shop', shop);
              setValue('inspector', {
                ...inspector,
                roleCode: _.find(UserRoleType, {
                  label: inspector.userRoleTitle,
                }).value,
              });
              const {
                shopInfo: { insurancePolicyNumber },
              } = myInfoLoadable.contents;

              setValue('paymentInformation.ICNY_CODE', insuranceCompany);
              setValue(
                'paymentInformation.insurancePolicyNumber',
                insurancePolicyNumber || ''
              );

              setInsurancePrice({ insurancePriceTables });
              setCheckPrice({ checkPriceTable });
              const [totalPrice] = calculatePrices();
            }
          }}
        />
      )}
      {isDialogOpened.customerSearch && (
        <SearchDialog.Customer
          userInfo={myInfoLoadable.contents}
          currentInspector={_.get(currentValues, 'inspector')}
          preSelected={{
            associationId: _.get(currentValues, 'associationId'),
            enterpriseId: _.get(currentValues, 'enterpriseId'),
            shopId: _.get(currentValues, 'shopId'),
          }}
          onHide={(customerData) => {
            setIsDialogOpened((ps) => ({ ...ps, customerSearch: false }));
            if (customerData) {
              const [customerCompany, customer] =
                separateCustomerData(customerData);
              setValue('customerCompany', {
                ...customerCompany,
                customerCompanyName: customerData.companyName,
              });
              setValue('customer', {
                ...customer,
                customCustomerName: customerData.companyName,
                tempKey: '',
              });
            }
          }}
        />
      )}
    </BlockUI>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};
export default React.memo(PCRegistrationForm, comparisonFn);

const RankComponent = (arr, currentValues, partNumber = true) => {
  return arr.map((item, idx) => {
    const frame = MAIN_FRAMES.find((f) => f.key === item.partNumber);

    return (
      <div key={`EXT_${idx}_${frame.dataKey}`}>
        <div className="flex flex-auto align-items-center justify-content-start gap-1">
          <Checkbox
            inputId={`chk_EXT_${idx}_${frame.dataKey}`}
            checked={['2', '3', '4'].includes(currentValues[frame.dataKey])}
            readOnly
          />
          <label htmlFor={`chk_EXT_${idx}_${frame.dataKey}`}>
            {/* {item.partNumber}.{item.label} */}
            {partNumber ? `${item.partNumber}.${item.label}` : `${item.label}`}
          </label>
        </div>
      </div>
    );
  });
};

const STATUS_ICON = [
  { value: 'X', icon: 'danger', text: '교환' },
  {
    value: 'W',
    icon: 'info',
    text: '판근, 용접',
  },
  { value: 'C', icon: 'warning', text: '부식' },
  { value: 'A', icon: 'warning', text: '흠집' },
  { value: 'U', icon: 'warning', text: '요철' },
  { value: 'T', icon: 'warning', text: '손상' },
];

const ONE_RANK = [
  { partNumber: '1', label: '후드' },
  {
    partNumber: '2',
    label: '프론트펜더',
  },
  { partNumber: '3', label: '도어' },
  {
    partNumber: '4',
    label: '트렁크리드',
  },
  {
    partNumber: '5',
    label: '라디에이터서포트(볼트체결부품)',
  },
];

const TOW_RANK = [
  {
    partNumber: '6',
    label: '쿼터패널',
  },
  {
    partNumber: '7',
    label: '루프패널',
  },
  {
    partNumber: '8',
    label: '사이드실패널',
  },
];

const A_RANK = [
  {
    partNumber: '9',
    label: '프론트패널',
  },
  {
    partNumber: '10',
    label: '크로스멤버',
  },
  {
    partNumber: '11',
    label: '인사이드패널',
  },
  {
    partNumber: '17',
    label: '트렁크플로워',
  },
  {
    partNumber: '18',
    label: '리어패널',
  },
];
